var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { mapMutations } from "vuex";
import routes from 'App/router/routes';
var CLIENT_HEADER_HEIGHT_DESKTOP = 101;
var CLIENT_HEADER_HEIGHT_MOBILE = 80;
var CLIENT_HEADER_HEIGHT_MOBILE_SMALL = 80;
var CLIENT_HEADER_NAV_HEIGHT = 50;
var MenuMixin = /** @class */ (function (_super) {
    __extends(MenuMixin, _super);
    function MenuMixin() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isMenuOpen = false;
        _this.headerIsSticky = false;
        return _this;
    }
    MenuMixin.prototype.created = function () {
        var _this = this;
        window.addEventListener('message', function (event) {
            if (window.postMessageHandler.isAllowedToProcessMessage(event) &&
                window.postMessageHandler.checkMessageType(event, "DEVICE_HEIGHT")) {
                _this.updateFrameHeight(event.origin, JSON.parse(event.data).size);
            }
        });
    };
    Object.defineProperty(MenuMixin.prototype, "basePath", {
        get: function () {
            return window.location.origin + this.relativePath;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuMixin.prototype, "relativePath", {
        get: function () {
            return window.application.relativePath || "";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuMixin.prototype, "routePath", {
        get: function () {
            return this.$route.path;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuMixin.prototype, "isActiveMenuItem", {
        get: function () {
            var _this = this;
            return function (path) {
                var url = path;
                if (url === '/') {
                    url = '';
                }
                return {
                    'active': _this.routePath === "/" + url ||
                        (url !== '' && _this.routePath.indexOf(url) !== -1),
                };
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuMixin.prototype, "isBuildStablePagesOpened", {
        get: function () {
            return this.routePath.indexOf("/game/pick-ten") !== -1 ||
                this.routePath.indexOf("/game/perms") !== -1;
        },
        enumerable: true,
        configurable: true
    });
    MenuMixin.prototype.navigateTo = function (event, path, navType) {
        event.preventDefault();
        var pathname = path;
        window.postMessageHandler.sendMessage({ "type": "MENU_CLOSE" });
        if (navType === 'mainNav') {
            window.gtm.navInteraction(pathname);
        }
        else {
            window.gtm.stickyFooterInteraction(pathname);
        }
        if ((this.$route.name && this.isVueRouter(pathname))) {
            if (this.routePath == pathname)
                return;
            this.$router.push({ path: "/" + pathname });
            this.hideSecondaryMenu();
            return;
        }
        if (pathname === '/') {
            pathname = '';
        }
        window.location.href = this.basePath + "/" + pathname;
    };
    MenuMixin.prototype.isVueRouter = function (path) {
        return _.find(routes, function (route) { return path.indexOf(route.path.substr(1)) !== -1; });
    };
    MenuMixin.prototype.updateFrameHeight = function (origin, screenSize) {
        var clientMenuHeight = 0;
        if (window.screen.width <= 362) {
            clientMenuHeight = CLIENT_HEADER_HEIGHT_MOBILE_SMALL;
        }
        else if (window.screen.width < 992) {
            clientMenuHeight = CLIENT_HEADER_HEIGHT_MOBILE;
        }
        else {
            clientMenuHeight = CLIENT_HEADER_HEIGHT_DESKTOP;
        }
        var screenHeight = +screenSize.match(/\d/g).join("") - clientMenuHeight - CLIENT_HEADER_NAV_HEIGHT;
        var params = { "type": "FRAME_SIZE", "size": screenHeight + "px" };
        window.postMessageHandler.sendMessage(params);
        this.$emit('on-screen-resize', screenHeight);
        window.localStorage.setItem('screenHeight', screenHeight.toString());
    };
    MenuMixin.prototype.openBlog = function () {
        window.postMessageHandler.sendMessage({ 'type': 'BLOG_PAGE_OPEN' });
    };
    MenuMixin = __decorate([
        Component({
            methods: __assign({}, mapMutations({
                'showSecondaryMenu': "sidebar/showSecondaryMenu",
                'hideSecondaryMenu': "sidebar/hideSecondaryMenu",
                'toggleSecondaryMenu': "sidebar/toggleSecondaryMenu"
            }))
        })
    ], MenuMixin);
    return MenuMixin;
}(Vue));
export default MenuMixin;
