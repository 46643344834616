var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { mapActions } from "vuex";
var LoadErrorHandler = /** @class */ (function (_super) {
    __extends(LoadErrorHandler, _super);
    function LoadErrorHandler() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(LoadErrorHandler.prototype, "loadErrors", {
        get: function () {
            return window.application.transactionMessage;
        },
        enumerable: true,
        configurable: true
    });
    LoadErrorHandler.prototype.created = function () {
        if (this.loadErrors) {
            this.loadErrorHandler();
        }
    };
    LoadErrorHandler.prototype.loadErrorHandler = function () {
        if (this.loadErrors.errorCode === "ERR4050" || this.loadErrors.errorCode === "ERR2103") {
            this.showErrorTransactionNotification({
                message: window.application.translations[this.loadErrors.errorCode]
            });
            return;
        }
        if (this.loadErrors.errorCode === "ERR2450") {
            window.postMessageHandler.sendMessage({ "type": "LOGOUT" });
            return;
        }
        if (this.loadErrors.success) {
            this.showSuccessStableCreatingNotification({
                message: window.application.translations['stable_creation_message']
            });
            return;
        }
        this.showNotification({
            title: window.application.translations['error_title'],
            message: window.application.translations['error_purchase_fail'],
            closeButtonStatus: true,
            buttonStatus: false
        });
    };
    LoadErrorHandler = __decorate([
        Component({
            methods: __assign({}, mapActions({
                'showErrorTransactionNotification': 'notification/showErrorTransactionNotification',
                'showSuccessStableCreatingNotification': 'notification/showSuccessStableCreatingNotification',
                'showNotification': 'notification/showNotification'
            }))
        })
    ], LoadErrorHandler);
    return LoadErrorHandler;
}(Vue));
export default LoadErrorHandler;
