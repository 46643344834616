import QueryBuilder from "App/services/graphql/base-query-builder";
import getMonthlyStatisticsFragments from "App/services/graphql/fragments/monthlyStatisticsFragments";
import { getMonthPaidStatisticsQuery, getMonthFreeStatisticsQuery, getMonthCharityStatisticsQuery, getMonthlyStatisticsQuery, getMonthlyStatisticsBeforeQuery, getMonthlyDetailQuery, getMonthlyPaidUserStatisticQuery, getMonthlyFreeUserStatisticQuery, getMonthlyCharityUserStatisticQuery } from "App/services/graphql/queries/monthlyStatistics";
import trainerFragment from "App/services/graphql/fragments/trainerFragment";
import ownerFragments from "App/services/graphql/fragments/ownerFragment";
import horseFragment from "App/services/graphql/fragments/horseFragment";
import raceParticipantFragment from "App/services/graphql/fragments/raceParticipantsFragment";
import raceFragment from "App/services/graphql/fragments/raceFragment";
import meetingFragment from "App/services/graphql/fragments/meetingFragment";
export var getMonthlyDataGraphQLQuery = new QueryBuilder([getMonthPaidStatisticsQuery, getMonthFreeStatisticsQuery, getMonthCharityStatisticsQuery], [getMonthlyStatisticsFragments], [{
        $amount: "Int",
        $date: "String!",
        $order: "[MonthlyStatisticFilter_order]",
        $userStable: "String",
        $stableName: "String"
    }]).generateQuery();
export var getMonthlyAdditionalDataGraphQLQuery = new QueryBuilder([getMonthlyStatisticsQuery], [getMonthlyStatisticsFragments], [{
        $type: "String",
        $date: "String",
        $amount: "Int",
        $after: "String",
        $order: "[MonthlyStatisticFilter_order]",
        $userStable: "String",
        $stableName: "String"
    }]).generateQuery();
export var getMonthlyAdditionalBeforeDataGraphQLQuery = new QueryBuilder([getMonthlyStatisticsBeforeQuery], [getMonthlyStatisticsFragments], [{
        $type: "String",
        $date: "String",
        $amount: "Int",
        $before: "String",
        $order: "[MonthlyStatisticFilter_order]",
        $userStable: "String",
        $stableName: "String"
    }]).generateQuery();
export var getMonthlyDetailStatisticGraphQLQuery = new QueryBuilder([getMonthlyDetailQuery], [trainerFragment,
    ownerFragments,
    horseFragment,
    raceParticipantFragment,
    raceFragment,
    meetingFragment,
    getMonthlyStatisticsFragments], [{
        $id: "ID!"
    }]).generateQuery();
export var getMonthlyUserStatisticGraphQLQuery = new QueryBuilder([getMonthlyPaidUserStatisticQuery, getMonthlyFreeUserStatisticQuery, getMonthlyCharityUserStatisticQuery], [getMonthlyStatisticsFragments], [{
        $date: "String"
    }]).generateQuery();
