import Vue from 'vue';
import Vuex from 'vuex';
import game from "./modules/game";
import perm from "./modules/perm";
import sidebar from "./modules/sidebar";
import stables from "./modules/stables";
import notification from "./modules/notification";
import loader from "./modules/loader";
import leaderboard from "./modules/leaderboard";
import leagues from "./modules/leagues";
import transfer from "./modules/transfer";
Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        game: game,
        perm: perm,
        sidebar: sidebar,
        stables: stables,
        loader: loader,
        leaderboard: leaderboard,
        notification: notification,
        leagues: leagues,
        transfer: transfer,
    },
    state: {
        errorMessages: [],
        refetchQueries: []
    },
    mutations: {
        addRefetchQueries: function (state, query) {
            state.refetchQueries.push(query);
        },
        removeRefetchQueries: function (state, query) {
            var index = _.findIndex(state.refetchQueries, function (item) {
                return item === query;
            });
            if (index !== -1) {
                state.refetchQueries.splice(index, 1);
            }
        },
        removeMessage: function (state, message) {
            var index = state.errorMessages.indexOf(message);
            var currentMessages = state.errorMessages;
            currentMessages.splice(index, 1);
            state.errorMessages = currentMessages;
        },
        updateErrorMessage: function (state, data, buttonIsAdded, func) {
            var text = data.graphQLErrors ? data.graphQLErrors.map(function (x) { return x.message; }).toString() : "Ooops...something went wrong";
            var message = {
                text: text,
                createdAt: new Date().getTime()
            };
            state.errorMessages = state.errorMessages.concat([message]);
        },
    }
});
export var gameEventBus = new Vue();
