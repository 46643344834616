var Scrollbar = function () { return import(
/* webpackChunkName: "scrollbar" */
'SonataPageBundle/theme/js/widgets/ScrollbarWidget.vue'); };
var Carousel = function () { return import(
/* webpackChunkName: "carousel" */
'./widgets/CarouselWidget'); };
import { BTabs, BTab, BModal, BCollapse, BTooltip, VBToggle, BDropdown, BDropdownItem } from 'bootstrap-vue';
var PlatformWidgets = {
    install: function (Vue, options) {
        Vue.component('b-tabs', BTabs);
        Vue.component('b-tab', BTab);
        Vue.component('b-modal', BModal);
        Vue.component('Scrollbar', Scrollbar);
        Vue.component('b-collapse', BCollapse);
        Vue.component('b-dropdown', BDropdown);
        Vue.component('b-dropdown-item', BDropdownItem);
        Vue.component('carousel', Carousel);
        Vue.component('b-tooltip', BTooltip);
        Vue.directive('b-toggle', VBToggle);
    },
};
export default PlatformWidgets;
