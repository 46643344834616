import _ from 'lodash';
import { LOADERS } from "App/config";
var state = {
    loaders: [],
};
var getters = {
    isGameLoaderEnabled: function (state) {
        return _.includes(state.loaders, LOADERS['GAME']);
    },
    isPickTenLoaderEnabled: function (state) {
        return _.includes(state.loaders, LOADERS['PICK_TEN']);
    },
    isAsideLoaderEnabled: function (state) {
        return _.includes(state.loaders, LOADERS['ASIDE']);
    },
    isStablesLoaderEnabled: function (state) {
        return _.includes(state.loaders, LOADERS['STABLES']);
    },
    isStableLoaderEnabled: function (state) {
        return _.includes(state.loaders, LOADERS['STABLE']);
    },
    isStableLoaderListEnabled: function (state) {
        return _.includes(state.loaders, LOADERS['STABLES_LIST']);
    },
    isParticipantsLoaderEnabled: function (state) {
        return _.includes(state.loaders, LOADERS['PARTICIPANTS']);
    },
    isHorseListLoaderEnabled: function (state) {
        return _.includes(state.loaders, LOADERS['HORSE_LIST']);
    },
    isLeaderBoardEnabled: function (state) {
        return _.includes(state.loaders, LOADERS['LEADER_BOARD']);
    },
    isLeaguesLoaderEnabled: function (state) {
        return _.includes(state.loaders, LOADERS['LEAGUES']);
    },
    isLeagueDetailLoaderEnabled: function (state) {
        return _.includes(state.loaders, LOADERS['LEAGUES_DETAIL']);
    },
    isPermLoaderEnabled: function (state) {
        return _.includes(state.loaders, LOADERS['PERM']);
    },
};
var mutations = {
    enableLoader: function (state, loader) {
        if (!_.includes(state.loaders, loader)) {
            state.loaders.push(loader);
        }
    },
    disableLoader: function (state, loader) {
        var index = _.findIndex(state.loaders, function (item) { return item === loader; });
        if (index !== -1) {
            state.loaders.splice(index, 1);
        }
    }
};
var actions = {
    enableLoaders: function (_a, loaders) {
        var state = _a.state, commit = _a.commit;
        _.forEach(loaders, function (loader) {
            commit('enableLoader', loader);
        });
    },
    disableLoaders: function (_a, loaders) {
        var state = _a.state, commit = _a.commit;
        _.forEach(loaders, function (loader) {
            commit('disableLoader', loader);
        });
    }
};
export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions
};
