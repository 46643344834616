import QueryBuilder from "App/services/graphql/base-query-builder";
import { getHorseQuery, getHorsesQuery, getHorseListQuery, getHorseListAdditionalQuery, getHorsesUsedInUserStable } from 'App/services/graphql/queries/horse';
import horseFragment from "App/services/graphql/fragments/horseFragment";
import trainerFragment from "App/services/graphql/fragments/trainerFragment";
import ownerFragment from "App/services/graphql/fragments/ownerFragment";
import ownerFragments from "App/services/graphql/fragments/ownerFragment";
import raceFragment from "App/services/graphql/fragments/raceFragment";
import raceParticipantsFragment from "App/services/graphql/fragments/raceParticipantsFragment";
import meetingFragment from "App/services/graphql/fragments/meetingFragment";
import poolRunnersFragment from "App/services/graphql/fragments/poolRunnersFragment";
export var getHorseGraphQLQuery = new QueryBuilder([getHorseQuery], [horseFragment, trainerFragment, ownerFragment], [
    {
        $id: "ID!"
    }
]).generateQuery();
export var getHorsesGraphQLQuery = new QueryBuilder([getHorsesQuery], [horseFragment, trainerFragment, ownerFragment], []).generateQuery();
export var getHorseListGraphQLQuery = new QueryBuilder([getHorseListQuery], [horseFragment,
    trainerFragment,
    ownerFragments,
    raceFragment,
    raceParticipantsFragment,
    meetingFragment], [{
        $first: 'Int',
        $order: "[HorseFilter_order]",
        $search: "String!"
    }]).generateQuery();
export var getHorseListAdditionalGraphQLQuery = new QueryBuilder([getHorseListAdditionalQuery], [horseFragment,
    trainerFragment,
    ownerFragments,
    raceFragment,
    raceParticipantsFragment,
    meetingFragment], [{
        $first: 'Int',
        $after: 'String!',
        $order: "[HorseFilter_order]",
        $search: "String!"
    }]).generateQuery();
export var getHorsesUsedInUserStableGraphQLQuery = new QueryBuilder([getHorsesUsedInUserStable], [horseFragment,
    trainerFragment,
    ownerFragments,
    raceFragment,
    raceParticipantsFragment,
    meetingFragment,
    poolRunnersFragment], [{
        $search: "String",
        $first: 'Int',
        $order: '[HorseFilter_order]'
    }]).generateQuery();
