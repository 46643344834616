import QueryBuilder from "App/services/graphql/base-query-builder";
/***** Queries *****/
import { getCurrentPermQuery, } from 'App/services/graphql/queries/perm';
/***** Mutations *****/
import { createPermMutation, updatePermMutation, createPermBoxMutation, updatePermBoxMutation, deletePermBoxMutation, addItemsToPermBox, removeItemsFromPermBox, purchasePerm } from 'App/services/graphql/mutations/userPerm';
/***** Fragments *****/
/***** Queries Builder*****/
export var getCurrentPermGraphQLQuery = new QueryBuilder([getCurrentPermQuery], [], []).generateQuery();
/***** Mutations Builder*****/
export var createPermGraphQLMutation = new QueryBuilder([createPermMutation], [], [{
        $name: "String!",
    }]).generateMutation();
export var updatePermGraphQLMutation = new QueryBuilder([updatePermMutation], [], [{
        $id: "ID!",
        $name: "String!",
    }]).generateMutation();
export var createPermBoxGraphQLMutation = new QueryBuilder([createPermBoxMutation], [], [{
        $name: "String!",
        $positions: "Int!",
        $perm: "String!",
        $items: "[String]"
    }]).generateMutation();
export var updatePermBoxGraphQLMutation = new QueryBuilder([updatePermBoxMutation], [], [{
        $id: "ID!",
        $name: "String!",
        $positions: "Int!",
        $items: "[String]"
    }]).generateMutation();
export var deletePermBoxGraphQLMutation = new QueryBuilder([deletePermBoxMutation], [], [{
        $id: "ID!"
    }]).generateMutation();
export var addItemsToPermBoxGraphQLMutation = new QueryBuilder([addItemsToPermBox], [], [{
        $id: "ID!",
        $items: "[String]!"
    }]).generateMutation();
export var removeItemsFromPermBoxGraphQLMutation = new QueryBuilder([removeItemsFromPermBox], [], [{
        $id: "ID!",
        $items: "[String]!"
    }]).generateMutation();
export var purchasePermGraphQLMutation = new QueryBuilder([purchasePerm], [], [{
        $id: "ID!",
    }]).generateMutation();
