export default {
    GAME: 'game',
    HORSES: 'horses',
    PICK_TEN: 'pick_ten',
    PICK_TEN_STABLE: 'pick_ten_stable',
    PICK_TEN_STABLE_DETAIL: 'pick_ten_stable_detail',
    PICK_TEN_LIST: 'pick_ten_list',
    PICK_TEN_CONFIRMATION: 'pick_ten_confirmation',
    PERMS: 'perms',
    HORSE_LIST: 'horse_list',
    LEAGUES: 'leagues',
    YOUR_LEAGUES: 'your_leagues',
    LEAGUE_DETAIL: 'league_detail',
    LEAGUE_DETAIL_HORSES: 'league_detail_horses',
    CREATE_LEAGUES: 'create_league',
    JOIN_LEAGUE: 'join_league',
    LEADER_BOARD: 'leaderboard',
    LEADER_BOARD_OVERVIEW: 'leaderboard_overview',
    LEADER_BOARD_OVERALL: 'leaderboard_overall',
    LEADER_BOARD_MONTHLY: 'leaderboard_monthly',
    MY_STABLES: 'myStables',
    MY_STABLE_LIST: "stablesList",
    MY_STABLE_HORSE_DETAIL: "horseDetail",
    MY_STABLE_SINGLE: "singleStable"
};
