import { ROUTES } from 'App/config';
import router from '../../router';
var state = {
    enabled: false,
    title: "",
    message: "",
    buttonText: "",
    buttonStatus: true,
    func: null,
    closeButtonStatus: false,
};
var getters = {};
var mutations = {
    toggleNotification: function (state, status) {
        state.enabled = status;
    },
    setData: function (state, _a) {
        var title = _a.title, message = _a.message, buttonText = _a.buttonText, buttonStatus = _a.buttonStatus, func = _a.func, closeButtonStatus = _a.closeButtonStatus;
        state.title = title;
        state.message = message;
        state.buttonText = buttonText;
        state.buttonStatus = buttonStatus;
        state.func = func;
        state.closeButtonStatus = closeButtonStatus;
    }
};
var actions = {
    showNotification: function (_a, _b) {
        var state = _a.state, commit = _a.commit;
        var title = _b.title, message = _b.message, buttonText = _b.buttonText, buttonStatus = _b.buttonStatus, func = _b.func, closeButtonStatus = _b.closeButtonStatus, _c = _b.toggleSidebar, toggleSidebar = _c === void 0 ? true : _c;
        commit('toggleNotification', true);
        commit('setData', {
            title: title,
            message: message,
            buttonText: buttonText,
            buttonStatus: buttonStatus,
            func: func,
            closeButtonStatus: closeButtonStatus
        });
        if (toggleSidebar) {
            commit('sidebar/toggleSidebarMobileState', false, { root: true });
        }
    },
    showBatchTransferRequestNotifications: function (_a) {
        var dispatch = _a.dispatch;
        var message = window.application.batchTransferRequestNotifications.join("\r\n");
        if (!message)
            return;
        dispatch('showNotification', {
            title: window.application.translations['horse_list_transfer_modal_title'],
            message: message,
            buttonStatus: true,
            buttonText: window.application.translations['horse_list_transfer_modal_ok'],
            func: function () { }
        });
    },
    showErrorTransactionNotification: function (_a, _b) {
        var dispatch = _a.dispatch;
        var message = _b.message;
        dispatch('showNotification', {
            title: window.application.translations['error_title'],
            message: message,
            buttonStatus: true,
            buttonText: window.application.translations['error_button_text'],
            func: function () {
                window.postMessageHandler.sendMessage({ 'type': 'NAVIGATE_ACCOUNT' });
            }
        });
    },
    showSuccessStableCreatingNotification: function (_a, _b) {
        var dispatch = _a.dispatch;
        var message = _b.message;
        var buttonStatus = true;
        if (_.includes([ROUTES['MY_STABLES'], ROUTES['MY_STABLE_LIST'], ROUTES['MY_STABLE_SINGLE']], router.currentRoute.name)) {
            buttonStatus = false;
        }
        dispatch('showNotification', {
            title: window.application.translations['success_title'],
            message: message,
            buttonText: window.application.translations['stable_redirect_button'],
            buttonStatus: buttonStatus,
            closeButtonStatus: true,
            func: function () {
                router.push({
                    name: ROUTES['MY_STABLES']
                });
            }
        });
    }
};
export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
};
