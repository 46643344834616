"use strict";
var GTM = /** @class */ (function () {
    function GTM() {
    }
    GTM.prototype.push = function (data) {
        window.dataLayer.push(data);
    };
    /*** STAGE 1 ***/
    /** Homepage interactions */
    GTM.prototype.ttfHpInteraction = function (nameOfButton) {
        this.push({
            'event': 'ttfHpInteraction',
            'interactionName': nameOfButton
        });
    };
    /** Search/shortlist interactions */
    GTM.prototype.searchBarUsed = function () {
        this.push({
            event: 'ttfSearchBarUsed',
        });
    };
    GTM.prototype.searchFilterUsed = function (filterType, filterValue) {
        this.push({
            'event': 'ttfSearchFilterUsed',
            'filterType': filterType,
            'filterValue': filterValue,
        });
    };
    GTM.prototype.searchTabSwitched = function (tabName) {
        this.push({
            'event': 'ttfSearchTabSwitched',
            'tabName': tabName,
        });
    };
    GTM.prototype.horseAddedToShortlist = function (horseName, numberOfHorsesInShortListAfterAdded) {
        this.push({
            'event': 'ttfHorseAddedToShortlist',
            'horseName': horseName,
            'shortlistSize': numberOfHorsesInShortListAfterAdded,
        });
    };
    GTM.prototype.horseRemovedFromShortlist = function (horseName, numberOfHorsesInShortListAfterRemoved) {
        this.push({
            'event': 'ttfHorseRemovedFromShortlist',
            'horseName': horseName,
            'shortlistSize': numberOfHorsesInShortListAfterRemoved,
        });
    };
    /** Footer interactions/nav bar */
    GTM.prototype.stickyFooterInteraction = function (nameOfClickedTab) {
        this.push({
            'event': 'ttfStickyFooterInteraction',
            'interactionName': nameOfClickedTab,
        });
    };
    GTM.prototype.navInteraction = function (nameOfClickedTab) {
        this.push({
            'event': 'ttfNav',
            'navName': nameOfClickedTab
        });
    };
    /** Pop Up boxes */
    GTM.prototype.popUpInteraction = function (nameOfPopUP, typeOfInteraction) {
        this.push({
            'event': 'ttfPopUp',
            'modalName': nameOfPopUP,
            'interaction': typeOfInteraction
        });
    };
    /*** STAGE 2 ***/
    /** Bet Started */
    GTM.prototype.betStarted = function (typeOfBet) {
        this.push({
            'event': 'ttfBetStarted',
            'mode': typeOfBet
        });
    };
    /** BoxesWe */
    GTM.prototype.boxStarted = function () {
        this.push({
            'event': 'ttfBoxStarted'
        });
    };
    GTM.prototype.boxCompleted = function (nameOfBox, positionOfBox, arrayOfHorses) {
        this.push({
            'event': 'ttfBoxCompleted',
            'boxName': nameOfBox,
            'boxposition': positionOfBox,
            'boxhorses': arrayOfHorses
        });
    };
    /** Stage 3 */
    /** Horse Page */
    /**  My Stables */
    GTM.prototype.myStablesNav = function (navName) {
        this.push({
            'event': 'ttfMyStablesNav',
            'navName': navName
        });
    };
    GTM.prototype.myStablesView = function (viewType, stableOrHorseName, totalCurrentPoints) {
        this.push({
            'event': 'ttfMyStablesView',
            'viewType': viewType,
            'name': stableOrHorseName,
            'totalPoints': totalCurrentPoints
        });
    };
    /** Leader board */
    GTM.prototype.lbTabToggle = function (tabName) {
        this.push({
            'event': 'ttfLbTabToggle',
            'tabname': tabName
        });
    };
    GTM.prototype.lbWeekToggle = function (currentWeek, direction) {
        this.push({
            'event': 'ttfLbWeekToggle',
            'currentweek': currentWeek,
            'direction': direction,
        });
    };
    GTM.prototype.lbFilter = function (filterType) {
        this.push({
            'event': 'ttfLbFilter',
            'filtertype': filterType
        });
    };
    GTM.prototype.lbStableCLicked = function (stableName, position, lbType) {
        this.push({
            'event': 'ttfLbStableCLicked',
            'stableName': stableName,
            'position': position,
            'lbtype': lbType,
        });
    };
    /** Leagues */
    GTM.prototype.leaguesTab = function (tabName) {
        this.push({
            'event': 'ttfLeaguesTab',
            'tabname': tabName
        });
    };
    GTM.prototype.leaguesSelect = function (leagueName) {
        this.push({
            'event': 'ttfLeaguesSelect',
            'leaguename': leagueName,
        });
    };
    GTM.prototype.createLeague = function (leagueName) {
        this.push({
            'event': 'ttfCreateLeague',
            'leaguename': leagueName
        });
    };
    GTM.prototype.joinLeague = function (leagueName) {
        this.push({
            'event': 'ttfJoinLeague',
            'leaguename': leagueName
        });
    };
    GTM.prototype.bannerClick = function (bannerName, bannerArea) {
        this.push({
            'event': 'ttfUpsellBanner',
            'bannerName': bannerName,
            'bannerArea': bannerArea
        });
    };
    GTM.prototype.betPromptBanner = function (horseName, betType) {
        this.push({
            'event': 'ttfAddToBetslip',
            'horseName': horseName,
            'betType': betType
        });
    };
    GTM.prototype.stableSmartPick = function () {
        this.push({
            'event': 'ttfSmartPick',
        });
    };
    GTM.prototype.stableClickDelete = function (typeOfBet) {
        this.push({
            'event': 'ttfDelete',
            'mode': typeOfBet,
        });
    };
    GTM.prototype.leaderboardMyTopViewToggle = function (toggleType) {
        this.push({
            'event': 'ttfLbStableViewToggle',
            'toggleType': toggleType,
        });
    };
    return GTM;
}());
module.exports = GTM;
