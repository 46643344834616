var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import _ from 'lodash';
var QueryBuilder = /** @class */ (function () {
    function QueryBuilder(query, fragments, parameters) {
        this.query = [];
        this.fragments = [];
        this.query = query;
        this.fragments = fragments;
        this.parameters = parameters;
    }
    Object.defineProperty(QueryBuilder.prototype, "transformedQueries", {
        get: function () {
            return !_.isEmpty(this.query) ? "{ " + this.query.join() + " }" : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryBuilder.prototype, "transformedFragments", {
        get: function () {
            return !_.isEmpty(this.fragments) ? _.concat(this.fragments.join()) : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QueryBuilder.prototype, "transformedParameters", {
        get: function () {
            var argumentsString = '';
            _.forEach(this.parameters, function (item) {
                argumentsString += _.reduce(item, function (result, value, key) {
                    return result + (key + ": " + value + " ");
                }, '');
            });
            return "(" + argumentsString + ")";
        },
        enumerable: true,
        configurable: true
    });
    QueryBuilder.prototype.requestString = function (type) {
        var query = type + " ";
        if (!_.isEmpty(this.parameters)) {
            query += "" + this.transformedParameters;
        }
        return query + " " + this.transformedQueries + " " + this.transformedFragments;
    };
    QueryBuilder.prototype.generateQuery = function () {
        return {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["", ""], ["", ""])), this.requestString('query')),
            error: function (error) {
                console.log(error);
            },
        };
    };
    QueryBuilder.prototype.generateMutation = function () {
        return {
            mutation: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["", ""], ["", ""])), this.requestString('mutation')),
            error: function (error) {
                console.log(error);
            },
        };
    };
    QueryBuilder.prototype.generateMultiMutation = function (data, alias) {
        var _this = this;
        if (this.query.length > 1)
            throw Error('generateMultiMutation method can accept only one query');
        var query = '';
        _.forEach(data, function (params, index) {
            var queryString = _this.query.join();
            _.forEach(params, function (item, key) {
                var value = _.isString(item) ? "\"" + item + "\"" : item;
                queryString = queryString.replace("$" + key, value);
            });
            query += "" + alias + (index + 1) + ": " + queryString;
        });
        return {
            mutation: gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["mutation {", "}"], ["mutation {", "}"])), query),
            error: function (error) {
                console.log(error);
            }
        };
    };
    return QueryBuilder;
}());
export default QueryBuilder;
var templateObject_1, templateObject_2, templateObject_3;
