import _ from 'lodash';
var state = {
    enabled: true,
    activeComponent: '',
    components: [],
    sidebarMobileState: false,
    secondaryMenuIsVisible: false,
    permInfoBlock: {
        name: '',
        combinations: null,
    }
};
var getters = {
    getActiveComponent: function (state) { return state.activeComponent; },
    getComponents: function (state) { return state.components; },
    getSidebarMobileState: function (state) { return state.sidebarMobileState; },
};
var mutations = {
    setActiveComponent: function (state, component) {
        state.activeComponent = component;
    },
    setComponents: function (state, components) {
        state.components = components;
    },
    toggleSidebarMobileState: function (state, status) {
        if (status) {
            document.body.classList.add('overlay-opened');
        }
        else {
            document.body.classList.remove('overlay-opened');
        }
        state.sidebarMobileState = status;
    },
    toggleSecondaryMenu: function (state) {
        state.secondaryMenuIsVisible = !state.secondaryMenuIsVisible;
        if (state.secondaryMenuIsVisible) {
            document.body.classList.add('overlay-opened');
        }
        else {
            document.body.classList.remove('overlay-opened');
        }
    },
    showSecondaryMenu: function (state) {
        state.secondaryMenuIsVisible = true;
        document.body.classList.add('overlay-opened');
    },
    hideSecondaryMenu: function (state) {
        state.secondaryMenuIsVisible = false;
        document.body.classList.remove('overlay-opened');
    },
    toggleSidebar: function (state, status) {
        state.enabled = status;
    },
    setPermInfoBlock: function (state, _a) {
        var name = _a.name, combinations = _a.combinations;
        state.permInfoBlock.name = name;
        state.permInfoBlock.combinations = combinations;
    }
};
var actions = {
    addComponents: function (_a, components) {
        var state = _a.state, commit = _a.commit;
        commit('setComponents', components);
        if (!_.isEmpty(components)) {
            var activeComponent = _.find(components, function (item) { return item.active; });
            var componentName = (activeComponent) ? activeComponent.name : _.head(components).name;
            commit('setActiveComponent', componentName);
        }
        else {
            commit('setActiveComponent', '');
        }
    },
    toggleSecondaryMenu: function (_a) {
        var state = _a.state, commit = _a.commit;
        commit('toggleSecondaryMenu');
        document.body.classList.toggle('overlay-opened');
    },
    resetSidebar: function (_a) {
        var commit = _a.commit, dispatch = _a.dispatch;
        dispatch('addComponents', []);
        commit('setActiveComponent', '');
    }
};
export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
};
