export function utilGetPermBoxStablesNumber(horsesLength, positions) {
    var calculateFactorial = function (num) {
        return num <= 1 ? 1 : num * calculateFactorial(num - 1);
    };
    /* this number is calculated by the formula
            return h! / (h-p)!*p!
  
            n - number of horses in box
            p - number of positions in box
            */
    if (horsesLength === 0)
        return 0;
    if (horsesLength === positions)
        return 1;
    var h = horsesLength;
    var p = positions;
    var diff = h - p;
    var hFactorial = calculateFactorial(h);
    var pFactorial = calculateFactorial(p);
    var diffFactorial = calculateFactorial(diff);
    return hFactorial / (diffFactorial * pFactorial);
}
