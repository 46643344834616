import { ROUTES, SEASON_STATUS_PRESEASON, SEASON_STATUS_IN_SEASON } from 'App/config';
var translations = window.application.translations;
var seasonStatus = window.application.season.status;
var user = window.application.user;
var Game = function () { return import(
/* webpackChunkName: "game" */
'../components/GameComponent.vue'); };
var HorseList = function () { return import(
/* webpackChunkName: "horse-list"*/
'App/components/Game/HorseListComponent.vue'); };
/***** PickTen *****/
var PickTen = function () { return import(
/* webpackChunkName: "pick-ten"*/
'App/components/Game/PickTenComponent.vue'); };
var PickTenStable = function () { return import(
/* webpackChunkName: "pick-ten-stable"*/
'App/components/Game/PickTen/StableComponent.vue'); };
var PickTenStableList = function () { return import(
/* webpackChunkName: "pick-ten-stable"*/
'App/components/Game/PickTen/StableListComponent.vue'); };
var PickTenConfirmation = function () { return import(
/* webpackChunkName: "pick-ten-confirmation"*/
'App/components/Game/PickTen/ConfirmationComponent.vue'); };
/***** Perms *****/
var Perms = function () { return import(
/* webpackChunkName: "perms-ten"*/
'App/components/Game/PermComponent.vue'); };
/***** My Stable *****/
var StablesList = function () { return import(
/* webpackChunkName: "stables-list" */
'../components/Stables/MyStablesComponent.vue'); };
var OverviewPreSeason = function () { return import(
/* webpackChunkName: "overview-my-stable" */
'../components/Stables/Stables/Overview.vue'); };
var StableDetails = function () { return import(
/* webpackChunkName: "stable-detail" */
'../components/Common/StableDetailsComponent.vue'); };
var HorseStables = function () { return import(
/* webpackChunkNameL "user-horses" */
'../components/Stables/Horses/HorseStablesComponent.vue'); };
/***** Horse List *****/
var HorseListPage = function () { return import(
/* webpackChunkName: "horse-list-page" */
'../components/HorseList/HorseListComponent.vue'); };
/***** Leagues *****/
var Leagues = function () { return import(
/* webpackChunkName: "leagues" */
'../components/Leagues/LeaguesComponent.vue'); };
var YourLeagues = function () { return import(
/* webpackChunkName: "your-leagues" */
'../components/Leagues/Leagues/YourLeaguesComponent.vue'); };
var CreateLeague = function () { return import(
/* webpackChunkName: "create-league" */
'../components/Leagues/Leagues/CreateLeagueComponent.vue'); };
var JoinLeague = function () { return import(
/* webpackChunkName: "join-league" */
'../components/Leagues/Leagues/JoinLeagueComponent.vue'); };
/***** Leagues *****/
var OverviewLeaderboard = function () { return import(
/* webpackChunkName: "overview-leaderboard" */
'../components/Leaderboard/Leader/Overview.vue'); };
var Leaderboard = function () { return import(
/* webpackChunkName: "leaderboard" */
'../components/Leaderboard/LeaderComponent.vue'); };
var LeaderboardDetail = function () { return import(
/* webpackChunkName: "leaderboard-detail" */
'../components/Leaderboard/Leader/LeaderboardDetailComponent.vue'); };
var LeagueDetail = function () { return import(
/* webpackChunkName: "league-detail" */
'../components/Leagues/Leagues/YourLeagues/LeagueDetailComponent.vue'); };
var LeagueDetailHorses = function () { return import(
/* webpackChunkName: "league-detail" */
'../components/Leagues/Leagues/YourLeagues/LeagueDetailHorsesComponent.vue'); };
var sidebarComponents = [
    {
        name: 'ShortList',
        title: 'My Shortlist',
        quantityGetterName: 'game/getShortListedHorsesLength',
        active: true
    },
    {
        name: 'AllHorses',
        title: 'All Horses',
        quantityGetterName: 'game/getHorsesLength',
        active: false
    }
];
var sidebarStablesComponents = [
    {
        name: 'ShortList',
        title: 'My Shortlist',
        quantityGetterName: 'game/getShortListedHorsesLength',
        active: true
    },
    {
        name: 'AllHorses',
        title: 'All Horses',
        quantityGetterName: 'game/getHorsesLength',
        active: false
    },
    {
        name: 'StableBanner',
        title: '',
    }
];
var preSeasonComponents = [
    {
        path: '/game',
        component: Game,
        name: ROUTES['GAME'],
        children: [
            {
                path: 'horses',
                name: ROUTES['HORSES'],
                component: HorseList,
                meta: {
                    title: translations.title_add_horses_your,
                    components: [
                        {
                            name: 'ShortList',
                            title: 'Your Shortlist',
                            quantityGetterName: 'game/getShortListedHorsesLength'
                        }
                    ]
                }
            },
            {
                path: 'pick-ten',
                name: ROUTES['STABLE'],
                component: PickTen,
                redirect: {
                    name: ROUTES['PICK_TEN_LIST'],
                },
                meta: {
                    title: translations.title_pick_ten,
                    components: sidebarComponents.slice()
                },
                children: [
                    {
                        path: 'stable',
                        name: ROUTES['PICK_TEN_STABLE'],
                        component: PickTenStable,
                        meta: {
                            title: translations.title_pick_ten,
                            components: sidebarComponents.slice()
                        }
                    },
                    {
                        path: 'stable/:id',
                        name: ROUTES['PICK_TEN_STABLE_DETAIL'],
                        component: PickTenStable,
                        meta: {
                            title: translations.title_pick_ten,
                            components: sidebarComponents.slice()
                        }
                    },
                    {
                        path: 'list',
                        name: ROUTES['PICK_TEN_LIST'],
                        component: PickTenStableList,
                        meta: {
                            title: translations.title_pick_ten,
                            components: sidebarComponents.slice()
                        }
                    },
                    {
                        path: 'confirmation',
                        name: ROUTES['PICK_TEN_CONFIRMATION'],
                        component: PickTenConfirmation,
                    }
                ],
            },
            {
                path: 'perms',
                name: ROUTES['PERMS'],
                component: Perms,
                meta: {
                    title: translations.title_perm,
                    components: sidebarComponents.slice()
                }
            },
        ]
    },
];
var routes = (seasonStatus === SEASON_STATUS_PRESEASON ? preSeasonComponents : []).concat([
    {
        path: '/stables',
        name: ROUTES['MY_STABLES'],
        component: StablesList,
        redirect: {
            name: ROUTES['MY_STABLE_LIST'],
        },
        children: [
            {
                path: 'overview',
                name: ROUTES['MY_STABLE_LIST'],
                component: OverviewPreSeason,
                meta: {
                    components: [
                        {
                            name: 'StableBanner',
                            title: '',
                        }
                    ]
                },
            },
            {
                path: ':id',
                name: ROUTES['MY_STABLE_SINGLE'],
                component: StableDetails,
                meta: {
                    components: (seasonStatus === SEASON_STATUS_PRESEASON ? sidebarStablesComponents : []).slice()
                }
            },
            {
                path: 'horse/:id',
                name: ROUTES['MY_STABLE_HORSE_DETAIL'],
                component: HorseStables,
                meta: {
                    components: (seasonStatus === SEASON_STATUS_PRESEASON ? sidebarStablesComponents : []).slice()
                }
            }
        ],
    },
    {
        path: '/leagues',
        name: ROUTES['LEAGUES'],
        component: Leagues,
        redirect: {
            name: ROUTES['YOUR_LEAGUES'],
        },
        children: [
            {
                path: 'your-leagues',
                name: ROUTES['YOUR_LEAGUES'],
                component: YourLeagues,
                meta: {
                    components: (user && seasonStatus === SEASON_STATUS_IN_SEASON ? [{ name: 'YourStables', title: '' }] : []).slice()
                },
            },
            {
                path: 'your-leagues/:id',
                name: ROUTES['LEAGUE_DETAIL'],
                component: LeagueDetail,
                meta: {
                    components: (user && seasonStatus === SEASON_STATUS_IN_SEASON ? [{ name: 'YourStables', title: '' }] : []).slice()
                },
            },
            {
                path: 'your-leagues/:id/stable/:stable_id',
                name: ROUTES['LEAGUE_DETAIL_HORSES'],
                component: LeagueDetailHorses,
                meta: {
                    components: (user && seasonStatus === SEASON_STATUS_IN_SEASON ? [{ name: 'YourStables', title: '' }] : []).slice()
                },
            },
            {
                path: 'create-league',
                name: ROUTES['CREATE_LEAGUES'],
                component: CreateLeague,
                meta: {
                    components: (user && seasonStatus === SEASON_STATUS_IN_SEASON ? [{ name: 'YourStables', title: '' }] : []).slice()
                }
            },
            {
                path: 'join-league',
                name: ROUTES['JOIN_LEAGUE'],
                component: JoinLeague,
                meta: {
                    components: (user && seasonStatus === SEASON_STATUS_IN_SEASON ? [{ name: 'YourStables', title: '' }] : []).slice()
                }
            }
        ],
    },
    {
        path: '/horse-list',
        name: ROUTES['HORSE_LIST'],
        component: HorseListPage,
    },
    {
        path: '/leaderboard',
        name: ROUTES['LEADER_BOARD'],
        component: Leaderboard,
        redirect: {
            name: ROUTES['LEADER_BOARD_OVERVIEW'],
        },
        children: [
            {
                path: 'overview',
                name: ROUTES['LEADER_BOARD_OVERVIEW'],
                component: OverviewLeaderboard,
                meta: {
                    components: (user ? [{ name: 'YourStables', title: '' }] : []).slice()
                },
            },
            {
                path: 'overall/:id',
                name: ROUTES['LEADER_BOARD_OVERALL'],
                component: LeaderboardDetail,
                meta: {
                    type: 'overall_statistics',
                    components: (user ? [{ name: 'YourStables', title: '' }] : []).slice()
                },
            },
            {
                path: 'monthly/:id',
                name: ROUTES['LEADER_BOARD_MONTHLY'],
                component: LeaderboardDetail,
                meta: {
                    type: 'monthly_statistics',
                    components: (user ? [{ name: 'YourStables', title: '' }] : []).slice()
                },
            }
        ],
    }
]);
export default routes.slice();
