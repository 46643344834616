// @ts-ignore
export { default as ROUTES } from './modules/routes/';
export { default as LOADERS } from './modules/loaders/';
export { default as LEAGUES } from './modules/leagues/';
export { default as LEADERBOARD } from './modules/leaderboard/';
export { default as HORSE_LIST } from './modules/horse-list/';
export { default as STABLE } from './modules/stable/';
export { default as PERMS } from './modules/perms/';
export { default as USER_HORSES } from './modules/user-horses';
export { default as REFETCHED_QUERIES } from './modules/refetched-queries';
export { default as BET_PROMPT } from './modules/bet-prompt';
export var POPUP_MENU_WIDTH_TRIGGERING = 768;
export var SEASON_STATUS_PRESEASON = 'preseason';
export var SEASON_STATUS_IN_SEASON = 'active';
export var SEASON_STATUS_IN_ACTIVE = 'inactive';
export var MAX_AMOUNT_OF_TRANSFERS = 2;
