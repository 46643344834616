var AllHorses = function () { return import(
/* webpackChunkName: "all-horses" */
'./components/Game/Sidebar/AllHorsesComponent.vue'); };
var Shortlist = function () { return import(
/* webpackChunkName: "shortlist" */
'./components/Game/Sidebar/ShortListComponent.vue'); };
var Heading = function () { return import(
/* webpackChunkName: "heading" */
'./components/HeadingComponent.vue'); };
var DesktopHeading = function () { return import(
/* webpackChunkName: "desktop-heading" */
'./components/DesktopHeadingComponent.vue'); };
var MainMenu = function () { return import(
/* webpackChunkName: "main-menu" */
'./components/MainMenuComponent'); };
var AsideComponent = function () { return import(
/* webpackChunkName: "aside-component" */
'./components/AsideComponent.vue'); };
var SecondaryMenu = function () { return import(
/* webpackChunkName: "secondary-menu" */
'./components/SecondaryMenuComponent'); };
var ErrorMessage = function () { return import(
/* webpackChunkName: "error-message" */
'./components/Error.vue'); };
var NotificationComponent = function () { return import(
/* webpackChunkName: "error" */
'./components/NotificationComponent.vue'); };
var Leader = function () { return import(
/* webpackChunkName: "leader" */
'./components/Leaderboard/LeaderComponent.vue'); };
var YourStables = function () { return import(
/* webpackChunkName: "your-stables" */
'./components/Common/Sidebar/YourStablesComponent.vue'); };
var SimpleBar = function () { return import(
/* webpackChunkName: "simple-bar" */
'./lib/SimpleBar/SimpleBarComponent.vue'); };
var PermInfo = function () { return import(
/* webpackChunkName: "perm-info" */
'./components/Common/Sidebar/PermInfoComponent.vue'); };
var SeasonAlert = function () { return import(
/* webpackChunkName: "season-alert" */
'./components/Common/SeasonAlertComponent.vue'); };
/***** Banner *****/
var LeagueBanner = function () { return import(
/* webpackChunkName: "league-banner" */
'App/components/Banners/LeagueBannerComponent.vue'); };
var StableBanner = function () { return import(
/* webpackChunkName: "stable-banner" */
'App/components/Banners/StableBannerComponent.vue'); };
var PlatformComponents = {
    install: function (Vue, options) {
        Vue.component('AsideComponent', AsideComponent);
        Vue.component('AllHorses', AllHorses);
        Vue.component('ShortList', Shortlist);
        Vue.component('Heading', Heading);
        Vue.component('DesktopHeading', DesktopHeading);
        Vue.component('MainMenu', MainMenu);
        Vue.component('SecondaryMenu', SecondaryMenu);
        Vue.component('YourStables', YourStables);
        Vue.component('ErrorMessage', ErrorMessage);
        Vue.component('Notification', NotificationComponent);
        Vue.component('SimpleBar', SimpleBar);
        Vue.component('PermInfo', PermInfo);
        Vue.component('SeasonAlert', SeasonAlert);
        Vue.component('LeagueBanner', LeagueBanner);
        Vue.component('StableBanner', StableBanner);
    },
};
export default PlatformComponents;
