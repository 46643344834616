import QueryBuilder from "App/services/graphql/base-query-builder";
import { getOverallPaidStatisticsQuery, getOverallFreeStatisticsQuery, getOverallCharityStatisticsQuery, getOverallStatisticsQuery, getOverallDetailQuery, getOverallPaidUserStatisticQuery, getOverallStatisticsBeforeQuery, getOverallFreeUserStatisticQuery, getOverallCharityUserStatisticQuery } from 'App/services/graphql/queries/overallStatistics';
import getOverallStatisticsFragments from "App/services/graphql/fragments/overallStatisticsFragments";
import trainerFragment from "App/services/graphql/fragments/trainerFragment";
import ownerFragments from "App/services/graphql/fragments/ownerFragment";
import horseFragment from "App/services/graphql/fragments/horseFragment";
import raceParticipantFragment from "App/services/graphql/fragments/raceParticipantsFragment";
import raceFragment from "App/services/graphql/fragments/raceFragment";
import meetingFragment from "App/services/graphql/fragments/meetingFragment";
export var getOverallDataGraphQLQuery = new QueryBuilder([getOverallPaidStatisticsQuery, getOverallFreeStatisticsQuery, getOverallCharityStatisticsQuery], [getOverallStatisticsFragments], [{
        $amount: "Int",
        $order: "[OverallStatisticFilter_order]",
        $userStable: "String",
        $stableName: "String"
    }]).generateQuery();
export var getOverallAdditionalDataGraphQLQuery = new QueryBuilder([getOverallStatisticsQuery], [getOverallStatisticsFragments], [{
        $type: "String",
        $amount: "Int",
        $after: "String",
        $order: "[OverallStatisticFilter_order]",
        $stableName: "String"
    }]).generateQuery();
export var getOverallAdditionalBeforeDataGraphQLQuery = new QueryBuilder([getOverallStatisticsBeforeQuery], [getOverallStatisticsFragments], [{
        $type: "String",
        $amount: "Int",
        $before: "String",
        $order: "[OverallStatisticFilter_order]",
        $stableName: "String"
    }]).generateQuery();
export var getOverallDetailStatisticGraphQLQuery = new QueryBuilder([getOverallDetailQuery], [trainerFragment,
    ownerFragments,
    horseFragment,
    raceParticipantFragment,
    raceFragment,
    meetingFragment,
    getOverallStatisticsFragments], [{
        $id: "ID!"
    }]).generateQuery();
export var getOverallUserStatisticGraphQLQuery = new QueryBuilder([getOverallPaidUserStatisticQuery, getOverallFreeUserStatisticQuery, getOverallCharityUserStatisticQuery], [getOverallStatisticsFragments], []).generateQuery();
