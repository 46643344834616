var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var state = {
    oldHorse: {},
    newHorse: {},
    stableTransfersCount: 0,
    isTransferringProcess: false
};
var mutations = {
    setOldHorse: function (state, _a) {
        var data = _a.data;
        state.oldHorse = data;
    },
    setNewHorse: function (state, _a) {
        var data = _a.data;
        state.newHorse = data;
    },
    toggleTransferingProcess: function (state, data) {
        state.isTransferringProcess = data;
    },
    setStableTransfersCount: function (state, _a) {
        var transfersCount = _a.transfersCount;
        state.stableTransfersCount = transfersCount;
    },
};
var actions = {
    initOldHorse: function (_a, date) {
        var commit = _a.commit;
        commit('toggleTransferingProcess', true);
        commit('setOldHorse', {
            data: __assign({}, date)
        });
    },
    initNewHorse: function (_a, date) {
        var commit = _a.commit;
        commit('setNewHorse', {
            data: __assign({}, date)
        });
    },
    clearState: function (_a) {
        var commit = _a.commit;
        commit('setNewHorse', { data: {} });
        commit('setOldHorse', { data: {} });
        commit('toggleTransferingProcess', false);
    }
};
export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
};
