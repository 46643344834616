import { ApolloClient } from 'apollo-client';
import VueApollo from 'vue-apollo';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import fetch from 'unfetch';
var httpLink = createHttpLink({
    uri: window.location.origin + '/api/graphql',
    fetch: fetch
});
var cache = new InMemoryCache();
export var apolloClient = new ApolloClient({
    link: httpLink,
    cache: cache,
});
export var apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});
