var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _ from "lodash";
var HorseCacheService = /** @class */ (function () {
    function HorseCacheService(store, query) {
        this.store = null;
        this.data = null;
        this.query = null;
        this.store = store;
        this.query = query;
        this.data = store.readQuery(query);
    }
    HorseCacheService.prototype.updateHorseById = function (id, data) {
        var index = _.findIndex(this.data.horses.edges, function (item) {
            return item.node.id === id;
        });
        if (index !== -1) {
            this.data.horses.edges.splice(index, 1, {
                node: data,
                __typename: "HorseEdge"
            });
            this.writeToCache(this.data);
        }
    };
    HorseCacheService.prototype.horseManipulation = function (horses, operator) {
        var _this = this;
        _.forEach(this.data.horses.edges, function (item, index) {
            if (_.includes(horses, item.node.id)) {
                _this.data.horses.edges[index].node.numberOfUsesInUserStables = _this.data.horses.edges[index].node.numberOfUsesInUserStables + operator;
            }
        });
        this.writeToCache(this.data);
    };
    HorseCacheService.prototype.writeToCache = function (data) {
        this.store.writeQuery(__assign({}, this.query, { data: data }));
    };
    return HorseCacheService;
}());
export default HorseCacheService;
