import _ from 'lodash';
var PostMessageHandler = /** @class */ (function () {
    function PostMessageHandler(origin) {
        this.origin = '';
        this.origin = origin;
    }
    PostMessageHandler.prototype.sendMessage = function (message) {
        if (_.isEmpty(this.origin)) {
            return;
        }
        if (window.self !== window.top) {
            var params = JSON.stringify(message);
            console.info('Data:', message, '\n', 'parent side:', this.origin);
            window.parent.postMessage(params, this.origin);
        }
    };
    PostMessageHandler.prototype.isAllowedToProcessMessage = function (event) {
        if (event.origin.indexOf('tote.live') >= 0 || event.origin.indexOf('tote.digital') >= 0 || event.origin.indexOf('tote.co.uk') >= 0) {
            if (!event.data || !_.isString(event.data))
                return false;
            return true;
        }
    };
    PostMessageHandler.prototype.checkMessageType = function (event, type) {
        var message = JSON.parse(event.data);
        if (!message.type)
            throw Error("The type option in the message is empty");
        if (message.type === type)
            return true;
        return false;
    };
    PostMessageHandler.prototype.processMessage = function (event, type, func) {
        if (this.checkMessageType(event, type) && func) {
            func();
        }
        return false;
    };
    return PostMessageHandler;
}());
export default PostMessageHandler;
