var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _ from 'lodash';
import { apolloClient } from 'App/config/apollo-client';
import { pickTenGraphQLQuery } from "App/services/graphql/api/pickTen";
import { stablePreseasonGraphQLQuery } from 'App/services/graphql/api/stable-preseason';
import { getPendingUserStablesGraphQLQuery, getFreeUserStablesGraphQlQuery, updateStableGraphQLMutation, deleteStableGraphQLMutation, purchaseTransactionStableGraphQLMutation, createStableGraphQLMutation, updateHorseInStableGraphQLMutation, getPaidUserStablesGroupedByPermQueryQLQuery, createFreeStableGraphQLMutation, getUserStablesInSeasonQueryGraphQLQuery, getUserSeasonStable, getUserAdditionalPaidStablesInSeasonQueryGraphQLQuery } from 'App/services/graphql/entity/userStable';
import { getHorsesGraphQLQuery } from "App/services/graphql/entity/horse";
import { updateStableMutation } from 'App/services/graphql/mutations/userStable';
import QueryBuilder from 'App/services/graphql/base-query-builder';
import { STABLE, LOADERS, REFETCHED_QUERIES, SEASON_STATUS_IN_SEASON, SEASON_STATUS_PRESEASON, SEASON_STATUS_IN_ACTIVE } from "App/config";
import HorseCacheService from "App/services/cache/HorseCacheService";
import { quickPickTenGraphQLQuery } from 'App/services/graphql/api/quickPickTen';
var state = {
    viewedUserStableId: null,
    stables: {
        pending: [],
        free: [],
        paid: [],
        quickPick: []
    },
    isFreeStableAvailable: application.user ? application.user.isFreeStableAvailable : null,
    paidStableCount: application.user ? application.user.paidStableCount : 0,
    pendingStableCount: application.user ? application.user.pendingStableCount : 0,
    seasonStatus: application.season.status,
    overallPosition: application.leaderBoard.overall.paidCount,
    userStableMap: {},
    selectedStable: null,
    selectedStableIndex: 0,
    purchasedStables: [],
    encodedSilkImage: application.user ? window.application.user.encodedSilkImage : window.application.base64EncodedAnonymousUserSilk,
    disabledHorseForReplaceId: null,
};
var getters = {
    getViewedUserStable: function (state) { return _.find(state.stables.pending, function (stable) {
        return stable._id == state.viewedUserStableId;
    }); },
    getViewedUserStablePendingStatus: function (state, getters) {
        var stable = getters.getViewedUserStable;
        if (stable)
            return stable.type == 'paid' && !stabstablePreseasonGraphQLQueryle.purchased;
    },
    getPendingStables: function (state, getters) {
        return state.stables.pending;
    },
    getQuickPickStables: function (state, getters) {
        return state.stables.quickPick;
    },
    getMyStablesLength: function (state) {
        return state.paidStableCount + state.pendingStableCount;
    },
    getPendingStablesLength: function (state, getters) { return getters.getPendingStables && getters.getPendingStables.length; },
    getFreeStableCount: function (state) { return (application.user && !state.isFreeStableAvailable) ? 1 : 0; },
    getPaidAndFreeStablesCount: function (state, getters) {
        return state.paidStableCount + getters.getFreeStablesLength;
    },
    getPurchasedStables: function (state) { return state.stables.paid; },
    getPinnedStables: function (state, getters) { return _.filter(getters.getStables, function (stable) {
        return stable.pinned;
    }); },
    getPinnedStablesLength: function (state, getters) { return getters.getPinnedStables && getters.getPinnedStables.length; },
    getPurchasedStablesLength: function (state, getters) { return getters.getPurchasedStables && getters.getPurchasedStables.length; },
    getFreeStables: function (state) { return state.stables.free; },
    getFreeStablesLength: function (state, getters) { return getters.getFreeStables && getters.getFreeStables.length; },
    pendingTransformedStables: function (state, getters) {
        var stableHorses = _.map(getters.getPendingStables, function (item) {
            return {
                id: item.node.id,
                _id: item.node._id,
                name: item.node.name,
                items: item.node.stable.items
            };
        });
        return _.map(stableHorses, function (stable) {
            return {
                name: stable.name,
                id: stable.id,
                _id: stable._id,
                horses: _.map(stable.items.edges, function (item) { return item.node; })
            };
        });
    },
    getSelectedStableData: function (state) {
        if (state.selectedStable && _.has(state.userStableMap, state.selectedStable._id)) {
            return state.userStableMap[state.selectedStable._id];
        }
        return {};
    },
    getSelectedStableHorses: function (state) {
        if (state.selectedStable && _.has(state.userStableMap, state.selectedStable._id)) {
            return state.userStableMap[state.selectedStable._id].stable.items.edges.map(function (horse) { return horse.node; });
        }
        return [];
    },
    getIsActiveSeason: function (state) {
        return state.seasonStatus === SEASON_STATUS_IN_SEASON ||
            state.seasonStatus === SEASON_STATUS_IN_ACTIVE;
    },
    getIsPreSeason: function (state) {
        return state.seasonStatus === SEASON_STATUS_PRESEASON;
    },
    isEmptyEncodedSilkImage: function (state) {
        return _.isEmpty(state.encodedSilkImage);
    }
};
var mutations = {
    setStables: function (state, _a) {
        var data = _a.data, key = _a.key;
        state.stables[key] = _.map(data, function (item) {
            return item;
        });
    },
    setViewedUserStableId: function (state, id) {
        state.viewedUserStableId = id;
    },
    setFreeStableStatus: function (state, status) {
        state.isFreeStableAvailable = status;
    },
    addUserStable: function (state, stable) {
        state.stables.pending.push({ node: stable });
    },
    addUserStables: function (state, _a) {
        var stables = _a.stables, key = _a.key;
        state.stables[key] = _.concat(state.stables[key], _.map(stables, function (item) {
            return item;
        }));
    },
    spliceDeletedStable: function (state, id) {
        state.stables.pending.splice(_.findIndex(state.stables.pending, function (stable) { return stable.node.id === id; }), 1);
    },
    setUpdatesToStableInSeason: function (state, item) {
        var updatedPinnedStable = _.find(state.stables.pinned, function (stable) { return stable.id === item.id; });
        var updatedUserStable = _.find(state.stables.paid, function (stable) { return stable.id === item.id; });
        updatedUserStable.pinned = item.pinned;
        if (updatedPinnedStable) {
            state.stables.pinned.splice(_.findIndex(state.stables.pinned, function (stable) { return stable.id === item.id; }), 1);
        }
        else {
            state.stables.pinned.push(updatedUserStable);
        }
    },
    updateUserStableMap: function (state, _a) {
        var userStableId = _a.userStableId, userStableData = _a.userStableData;
        state.userStableMap[userStableId] = userStableData;
    },
    updateSelectedStable: function (state, _a) {
        var userStable = _a.userStable, userStableIndex = _a.userStableIndex;
        state.selectedStable = userStable;
        state.selectedStableIndex = userStableIndex;
    },
    setPurchasedStables: function (state, data) {
        state.purchasedStables = data;
    },
    setEncodedSilkImage: function (state, image) {
        state.encodedSilkImage = image;
    },
    setAmountOfPaidStables: function (state, amount) {
        state.paidStableCount = amount;
    },
    setDisabledHorseIdForReplace: function (state, horses_id) {
        state.disabledHorseForReplaceId = horses_id;
    }
};
var actions = {
    fetchPickTenStables: function (_a) {
        var commit = _a.commit;
        return new Promise(function (resolve, reject) {
            apolloClient.query(pickTenGraphQLQuery)
                .then(function (_a) {
                var data = _a.data;
                commit('setStables', { data: data.pending.edges, key: 'pending' });
                commit('setStables', { data: data.free.edges, key: 'free' });
                resolve(data);
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
            });
        });
    },
    fetchQuickPickTenStables: function (_a) {
        var commit = _a.commit;
        return new Promise(function (resolve, reject) {
            apolloClient.query(quickPickTenGraphQLQuery)
                .then(function (_a) {
                var data = _a.data;
                commit('setStables', { data: data.quickPick.edges, key: 'quickPick' });
                resolve(data);
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
            });
        });
    },
    fetchPaidStablesFilterByName: function (_a, name) {
        var commit = _a.commit;
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(getPaidUserStablesGroupedByPermQueryQLQuery, {
                variables: {
                    name: name,
                    first: STABLE['QUERY_AMOUNT']
                },
                fetchPolicy: 'no-cache',
            }))
                .then(function (_a) {
                var data = _a.data;
                resolve(data);
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
                reject(err);
            });
        });
    },
    fetchUserPurchasedStables: function (_a) {
        var commit = _a.commit;
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(stablePreseasonGraphQLQuery, {
                variables: {
                    first: STABLE['QUERY_AMOUNT']
                },
                fetchPolicy: 'no-cache',
            }))
                .then(function (_a) {
                var data = _a.data;
                commit('setStables', { data: data.free.edges, key: 'free' });
                commit('setStables', { data: data.paid.edges, key: 'paid' });
                commit('setAmountOfPaidStables', data.paid.totalCount);
                resolve();
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
            });
        });
    },
    fetchUserPurchasedStablesInSeason: function (_a) {
        var commit = _a.commit;
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(getUserStablesInSeasonQueryGraphQLQuery, {
                variables: {
                    first: STABLE['QUERY_AMOUNT']
                },
                fetchPolicy: 'cache-first',
            }))
                .then(function (_a) {
                var data = _a.data;
                commit('setStables', { data: data.free.edges, key: 'free' });
                commit('setStables', { data: data.paid.edges, key: 'paid' });
                commit('setAmountOfPaidStables', data.paid.totalCount);
                resolve();
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
            });
        });
    },
    fetchUserAdditionalPaidStablesInSeason: function (_a, variables) {
        var commit = _a.commit;
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(getUserAdditionalPaidStablesInSeasonQueryGraphQLQuery, {
                variables: variables,
                fetchPolicy: 'cache-first',
            }))
                .then(function (_a) {
                var data = _a.data;
                resolve(data);
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
                reject(err);
            });
        });
    },
    purchaseUserStable: function (_a, userStables) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return new Promise(function (resolve, reject) {
            apolloClient.mutate(Object.assign(purchaseTransactionStableGraphQLMutation, {
                variables: {
                    userStables: userStables
                }
            }))
                .then(function (res) {
                if (res.data.createPurchaseTransactionDTO.purchaseTransactionDTO.errorCode === "ERR4050" || res.data.createPurchaseTransactionDTO.purchaseTransactionDTO.errorCode === "ERR2103") {
                    dispatch('notification/showErrorTransactionNotification', {
                        message: window.application.translations[res.data.createPurchaseTransactionDTO.purchaseTransactionDTO.errorCode]
                    }, { root: true });
                    resolve();
                    return;
                }
                if (res.data.createPurchaseTransactionDTO.purchaseTransactionDTO.errorCode === "ERR2450") {
                    window.postMessageHandler.sendMessage({ "type": "LOGOUT" });
                    resolve();
                    return;
                }
                if (res.data.createPurchaseTransactionDTO.purchaseTransactionDTO.success) {
                    dispatch('updateStatusForPurchasedStables', userStables)
                        .then(function (res) {
                        commit('addRefetchQueries', REFETCHED_QUERIES['HORSE_LIST'], { root: true });
                        resolve(res);
                    });
                    return;
                }
                dispatch('notification/showNotification', {
                    title: window.application.translations['error_title'],
                    message: window.application.translations['error_purchase_fail'],
                    closeButtonStatus: true,
                    buttonStatus: false
                }, { root: true });
                resolve();
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
                reject(err);
            });
        });
    },
    deleteUserStable: function (_a, _b) {
        var commit = _a.commit, dispatch = _a.dispatch;
        var id = _b.id, type = _b.type;
        return new Promise(function (resolve, reject) {
            apolloClient.mutate(Object.assign(deleteStableGraphQLMutation, {
                variables: {
                    id: id
                },
                update: function (store) {
                    if (type === 'free') {
                        var query = getFreeUserStablesGraphQlQuery;
                        var key = 'free';
                    }
                    else {
                        var query = getPendingUserStablesGraphQLQuery;
                        var key = 'pending';
                    }
                    var data = store.readQuery(query);
                    var index = _.findIndex(data[key].edges, function (item) {
                        return item.node.id === id;
                    });
                    if (index !== -1) {
                        var horses = _.map(data[key].edges[index].node.stable.items.edges, function (item) {
                            return item.node.id;
                        });
                        if (type === 'free') {
                            var horseCache = new HorseCacheService(store, getHorsesGraphQLQuery);
                            horseCache.horseManipulation(horses, -1);
                        }
                        data[key].edges.splice(index, 1);
                        store.writeQuery(__assign({}, query, { data: data }));
                    }
                }
            }))
                .then(function (res) {
                dispatch('fetchPickTenStables');
                dispatch('game/fetchHorses', null, { root: true });
                resolve();
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
            });
        });
    },
    createUserStable: function (_a, stable) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return new Promise(function (resolve, reject) {
            apolloClient.mutate(Object.assign(createStableGraphQLMutation, {
                variables: {
                    name: stable.name,
                    type: stable.type,
                    horses: stable.horses
                },
                update: function (store, _a) {
                    var createUserStableDTO = _a.data.createUserStableDTO;
                    if (createUserStableDTO.userStableDTO && createUserStableDTO.userStableDTO.userStable) {
                        var data = store.readQuery(getPendingUserStablesGraphQLQuery);
                        data.pending.edges.push({
                            node: createUserStableDTO.userStableDTO.userStable,
                            __typename: "UserStableEdge"
                        });
                        store.writeQuery(__assign({}, getPendingUserStablesGraphQLQuery, { data: data }));
                    }
                }
            }))
                .then(function (res) {
                resolve(res);
                dispatch('game/fetchHorses', null, { root: true });
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
                reject(err);
            });
        });
    },
    updateUserStable: function (_a, stable) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return new Promise(function (resolve, reject) {
            apolloClient.mutate(Object.assign(updateStableGraphQLMutation, {
                variables: {
                    id: stable.id,
                    name: stable.name,
                    pinned: stable.pinned
                },
                update: function (store) {
                    if (stable.type === "free") {
                        var horseCache = new HorseCacheService(store, getHorsesGraphQLQuery);
                        horseCache.horseManipulation(stable.horses, +1);
                    }
                },
                refetchQueries: [pickTenGraphQLQuery],
                awaitRefetchQueries: true
            }))
                .then(function (res) {
                dispatch('game/fetchHorses', null, { root: true });
                resolve(res);
            })
                .catch(function (err) {
                reject(err);
            });
        });
    },
    createFreeStable: function (_a, stable) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return new Promise(function (resolve, reject) {
            apolloClient.mutate(Object.assign(createFreeStableGraphQLMutation, {
                variables: {
                    userStable: stable.id
                },
                update: function (store) {
                    var horseCache = new HorseCacheService(store, getHorsesGraphQLQuery);
                    horseCache.horseManipulation(stable.horses, +1);
                },
                refetchQueries: [pickTenGraphQLQuery],
                awaitRefetchQueries: true
            }))
                .then(function (res) {
                dispatch('game/fetchHorses', null, { root: true });
                resolve(res);
            })
                .catch(function (err) {
                reject(err);
            });
        });
    },
    updateHorsesInUserStable: function (_a, _b) {
        var commit = _a.commit, dispatch = _a.dispatch;
        var stable = _b.stable, previousHorses = _b.previousHorses;
        return new Promise(function (resolve, reject) {
            apolloClient.mutate(Object.assign(updateHorseInStableGraphQLMutation, {
                variables: {
                    userStable: stable.id,
                    name: stable.name,
                    horses: stable.horses
                },
                update: function (store) {
                    var horseCache = new HorseCacheService(store, getHorsesGraphQLQuery);
                    horseCache.horseManipulation(previousHorses, -1);
                }
            }))
                .then(function (res) {
                dispatch('game/fetchHorses', null, { root: true });
                resolve(res);
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
                reject(err);
            });
        });
    },
    replaceHorsesInUserStable: function (_a, _b) {
        var commit = _a.commit, dispatch = _a.dispatch;
        var stable = _b.stable, oldHorse = _b.oldHorse, newHorse = _b.newHorse;
        return new Promise(function (resolve, reject) {
            apolloClient.mutate(Object.assign(updateHorseInStableGraphQLMutation, {
                variables: {
                    userStable: stable.id,
                    name: stable.name,
                    horses: stable.horses
                },
                update: function (store) {
                    var horseCache = new HorseCacheService(store, getHorsesGraphQLQuery);
                    horseCache.horseManipulation([oldHorse], -1);
                }
            }))
                .then(function (res) {
                dispatch('game/fetchHorses', null, { root: true });
                resolve(res);
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
                reject(err);
            });
        });
    },
    updateStatusForPurchasedStables: function (_a, userStables) {
        var commit = _a.commit, dispatch = _a.dispatch, getters = _a.getters;
        return new Promise(function (resolve, reject) {
            apolloClient.mutate(Object.assign(new QueryBuilder([updateStableMutation], [], []).generateMultiMutation(_.map(getters.pendingTransformedStables, function (stable) {
                return {
                    id: stable.id,
                    name: stable.name,
                    type: "paid",
                    pinned: false
                };
            }), 'stable'), {
                update: function (store) {
                    var data = store.readQuery(getPendingUserStablesGraphQLQuery);
                    data.pending.edges = [];
                    store.writeQuery(__assign({}, getPendingUserStablesGraphQLQuery, { data: data }));
                }
            })).then(function (res) {
                commit('setPurchasedStables', res.data);
                dispatch('fetchPickTenStables');
                resolve();
            });
        });
    },
    purchaseUpdateStables: function (_a, userStables) {
        var commit = _a.commit, dispatch = _a.dispatch, getters = _a.getters;
        return new Promise(function (resolve, reject) {
            //dispatch('purchaseUserStable', userStables);
            apolloClient.mutate(new QueryBuilder([updateStableMutation], [], []).generateMultiMutation(_.map(getters.pendingTransformedStables, function (stable) {
                return {
                    id: stable.id,
                    name: stable.name,
                    type: "paid",
                    pinned: false
                };
            }), 'stable')).then(function (res) {
                resolve();
            });
        });
    },
    fetchStableData: function (_a, _b) {
        var commit = _a.commit, state = _a.state, getters = _a.getters;
        var userStable = _b.userStable;
        commit('loader/enableLoader', LOADERS['STABLES'], { root: true });
        var cache = (getters.getIsPreSeason) ? 'no-cache' : 'cache-first';
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(getUserSeasonStable, {
                variables: {
                    id: userStable.id
                },
                fetchPolicy: cache
            }))
                .then(function (_a) {
                var data = _a.data;
                commit('updateUserStableMap', {
                    userStableId: userStable._id, userStableData: data.userStable
                });
                resolve();
            })
                .catch(function (err) { return commit('updateErrorMessage', err, { root: true }); })
                .then(function () {
                commit('loader/disableLoader', LOADERS['STABLES'], { root: true });
            });
        });
    },
    updateSelectedStable: function (_a, _b) {
        var commit = _a.commit, state = _a.state, dispatch = _a.dispatch, getters = _a.getters;
        var userStable = _b.userStable, userStableIndex = _b.userStableIndex;
        if (userStable && (getters.getIsPreSeason || !_.has(state.userStableMap, userStable._id))) {
            dispatch('fetchStableData', { userStable: userStable }).then(function () {
                commit('updateSelectedStable', { userStable: userStable, userStableIndex: userStableIndex });
            });
        }
        else {
            commit('updateSelectedStable', { userStable: userStable, userStableIndex: userStableIndex });
        }
    }
};
export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
};
