export var getPickTenUserStableQuery = "\n    userStable(id: $id){\n        id\n        _id\n        name\n        points\n        floatPoints\n        type\n        purchased\n        pinned\n        stable {\n            items {\n                edges {\n                    node {\n                        ...horse\n                        owner {\n                            ...owner\n                        }\n                        trainer{\n                            ...trainer\n                        }\n                    }\n                }\n            }\n        }\n    }\n";
export var getQuickPickStableQuery = "\n    quickPick: quickPickSlots(order: {priority: \"ASC\"}) {\n        edges {\n          node {\n            priority\n            type\n            horses {\n              edges {\n                node {\n                  name\n                  age\n                  id\n                  name\n                  silkPath\n                  pickRate\n                  form\n                  rating\n                  owner {\n                    ...owner\n                  }\n                  trainer{\n                    ...trainer\n                  }\n                  _id\n                }\n              }\n            }\n          }\n        }\n      }\n";
export var getFreeUserStableQuery = "\n    free: userStables(currentUser: true, type: \"free\"){\n        edges{\n            node{\n               ...userStable,\n               stable {\n                    items {\n                        edges {\n                            node {\n                            id\n                            _id\n                            name\n                            silkPath\n                            }\n                        }\n                    }\n                }\n            }\n        }\n    }\n";
export var getPendingUserStablesQuery = "\n    pending: userStables(currentUser: true, purchased: false){\n        edges{\n            node{\n                ...userStable\n                stable {\n                    items {\n                        edges {\n                            node {\n                            id\n                            _id\n                            name\n                            silkPath\n                            }\n                        }\n                    }\n                }\n            }\n        }\n    }\n";
export var getPaidUserStablesGroupedByPermQuery = "\n    paid: userStables(currentUser: true, purchased: true, name: $name, first: $first, types: [\"paid\", \"charity\"], order: {floatPoints: \"desc\", id: \"asc\"}){\n        edges{\n            node{\n               ...userStable\n                perm {\n                    id\n                    name\n                }\n            }\n            cursor\n        }\n        totalCount\n    }\n";
export var getAdditionalPaidUserStablesQuery = "\n    paid: userStables(currentUser: true, purchased: true, name: $name, first: $first, types: [\"paid\", \"charity\"], after: $after, order: {floatPoints: \"desc\", id: \"asc\"}){\n        edges{\n            node{\n               ...userStable\n               perm {\n                  id\n                  name\n               }\n            }\n            cursor\n        }\n        totalCount\n    }\n";
export var getUserStableQuery = "\n   userStable(id: $id){\n      id\n      _id\n      name\n      points\n      floatPoints\n      type\n      purchased\n      pinned\n      perm{\n        id\n        name\n        combinations\n      }\n      transfers {\n          edges {\n              node {\n                  createdAt\n                  newHorse {\n                      id\n                      _id\n                  }\n              }\n          }\n      }\n\n      user {\n          base64EncodedSilkImage\n      }\n\n      stable {\n          items {\n              edges {\n                  node {\n                      ...horse\n                      raceParticipants {\n                          edges {\n                              node {\n                                  ...raceParticipants\n                                  poolRunners {\n                                    edges {\n                                      node {\n                                        ...poolRunner\n                                      }\n                                    }\n                                }\n                                  race {\n                                      ...race\n                                      meeting {\n                                          ...meeting\n                                      }\n                                  }\n                              }\n                          }\n                      }\n                      owner {\n                          ...owner\n                      }\n                      trainer{\n                          ...trainer\n                      }\n                  }\n              }\n          }\n      }\n  }\n";
export var getUserStablesForLeagueQuery = "\n    userStables(currentUser: true, purchased: true, first: $first){\n        edges{\n            node {\n                id\n                _id\n                name\n            }\n            cursor\n        }\n    }\n";
export var getAdditionalOverallLeagueUserStablesQuery = "\n    userStables(currentUser: true, purchased: true, first: $first, after: $after) {\n        edges{\n            node {\n                id\n                _id\n                name\n            }\n            cursor\n        }\n    }\n";
export var getUserStablesByHorseQuery = "\n    userStables(currentUser: true, horse: $horseId, name: $name, purchased: true, first: $first) {\n        edges {\n            node {\n                ...userStable\n                perm {\n                    id\n                    name\n                }\n            },\n            cursor\n        }\n    }\n";
export var getUserStablesByHorseAdditionalQuery = "\n    userStables(currentUser: true, horse: $horseId, name: $name, purchased: true, first: $first, after: $after) {\n        edges {\n            node {\n                ...userStable\n                perm {\n                    id\n                    name\n                }\n            },\n            cursor\n        }\n    }\n";
export var getUserSeasonStableQuery = "\n    userStable(id: $id) {\n        name\n        points\n        floatPoints\n        type\n        overallStatistics {\n            edges {\n                node {\n                    points\n                    form\n                    position\n                }\n            }\n        }\n\n        weeklyStatistics(order: {date:\"DESC\"}) {\n            edges {\n                node {\n                    position\n                    points\n                    form\n                    positionsMoved\n                }\n            }\n        }\n\n        transfers {\n            edges {\n                node {\n                    createdAt\n                    newHorse {\n                        id\n                        _id\n                    }\n                }\n            }\n        }\n\n        user {\n            base64EncodedSilkImage\n        }\n\n        stable {\n            items {\n                edges {\n                    node {\n                        ...horse\n                        trainer {\n                            ...trainer\n                        }\n                        owner {\n                            ...owner\n                        }\n                        raceParticipants {\n                            edges {\n                                node {\n                                    ...raceParticipants\n                                    race {\n                                        ...race\n                                        meeting {\n                                            ...meeting\n                                        }\n                                    }\n                                }\n                            }\n                        }\n                    }\n                }\n            }\n        }\n    }\n";
export var getUserStablesForLeaderboardQuery = "\n  userStables(currentUser: true, purchased: true, first: $first, type: \"paid\", after: $after){\n    edges{\n      node{\n        id\n        _id\n        name\n      }\n      cursor\n    }\n  }\n";
export var getUserStablesByPermQuery = "\n  userStables(perm: $perm, first: $first, after: $after, order: $order){\n      edges{\n          node{\n             ...userStable\n              perm {\n                  id\n                  name\n              }\n          }\n          cursor\n      }\n  }\n";
export var getStablesByName = "\n  userStables(purchased: true, first: 25, type: $type, name: $name, currentUser: true){\n      edges{\n          node{\n              id\n              _id\n              name\n          }\n      }\n  }\n";
export var getUserPaidStablesInSeasonQuery = "\n      paid: userStables(currentUser: true, purchased: true, first: $first, types: [\"paid\", \"charity\"], order: {overallStatistics_points:\"desc\"}){\n      edges{\n          node{\n              ...userStable\n              overallStatistics {\n                  edges {\n                      node {\n                          points\n                          form\n                          position\n                          positionsMoved\n                      }\n                  }\n              }\n          }\n          cursor\n      }\n      totalCount\n  }\n";
export var getUserFreeStablesInSeasonQuery = "\n      free: userStables(currentUser: true, purchased: true, first: $first, type: \"free\", order: {overallStatistics_points:\"desc\"}){\n      edges{\n          node{\n              ...userStable\n              overallStatistics {\n                  edges {\n                      node {\n                          points\n                          form\n                          position\n                          positionsMoved\n                      }\n                  }\n              }\n          }\n          cursor\n      }\n      totalCount\n  }\n";
export var getUserAdditionalPaidStablesInSeasonQuery = "\n      paid: userStables(currentUser: true, purchased: true, first: $first, after: $after, name: $name, types: [\"paid\", \"charity\"], order: $order){\n      edges{\n          node{\n              ...userStable\n              overallStatistics {\n                  edges {\n                      node {\n                          points\n                          form\n                          position\n                          positionsMoved\n                      }\n                  }\n              }\n          }\n          cursor\n      }\n      totalCount\n  }\n";
