export default {
    GAME: 'game',
    PICK_TEN: 'pick_ten',
    STABLES: 'stables',
    STABLE: 'stable',
    PARTICIPANTS: 'participants',
    ASIDE: 'aside',
    LEADER_BOARD: 'leaderboard',
    STABLES_LIST: 'stable_list',
    HORSE_LIST: 'horse_list',
    LEAGUES: 'leagues',
    LEAGUES_DETAIL: 'leagues_detail',
    PERM: 'perm',
};
