var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _ from 'lodash';
import { apolloClient } from 'App/config/apollo-client';
import { getLeaguesInitialData, getUserStablesForLeague, getAdditionalCurrentUserLeagues, getSeasonLeagues, } from "App/services/graphql/entity/leagues";
import { LOADERS, LEAGUES, STABLE } from 'App/config';
var state = {
    userLeagues: [],
    userStables: [],
    initialСursor: '',
    selectedLeague: null,
};
var mutations = {
    setStables: function (state, _a) {
        var data = _a.data;
        state.userStables = data;
    },
    addUserStables: function (state, data) {
        state.userStables = _.concat(state.userStables, data);
    },
    setUserLeagues: function (state, _a) {
        var data = _a.data;
        state.userLeagues = data;
    },
    addUserLeagues: function (state, data) {
        state.userLeagues = _.concat(state.userLeagues, data);
    },
    setCursor: function (state, _a) {
        var data = _a.data;
        state.initialСursor = data;
    },
    setSelectedLeague: function (state, league) {
        state.selectedLeague = league;
    }
};
var actions = {
    fetchLeagues: function (_a, sortConfig) {
        var state = _a.state, commit = _a.commit;
        commit('loader/enableLoader', LOADERS['LEAGUES'], { root: true });
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(getLeaguesInitialData, {
                variables: {
                    first: LEAGUES['QUERY_AMOUNT']
                },
                fetchPolicy: 'no-cache',
            }))
                .then(function (_a) {
                var data = _a.data;
                commit('setUserLeagues', { data: data.leagues.edges });
                resolve(data);
            })
                .catch(function (err) {
                reject(err);
            })
                .then(function () {
                commit('loader/disableLoader', LOADERS['LEAGUES'], { root: true });
            });
        });
    },
    fetchAdditionalLeagues: function (_a) {
        var state = _a.state, commit = _a.commit;
        commit('loader/enableLoader', LOADERS['LEAGUES'], { root: true });
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(getAdditionalCurrentUserLeagues, {
                variables: {
                    first: LEAGUES['QUERY_AMOUNT'],
                    after: _.last(state.userLeagues).cursor
                },
                fetchPolicy: 'no-cache',
            }))
                .then(function (res) {
                if (!_.isEmpty(res.data.leagues.edges)) {
                    commit('addUserLeagues', res.data.leagues.edges);
                }
                resolve(res.data.leagues.edges);
            })
                .catch(function (err) {
                reject(err);
            })
                .then(function () {
                commit('loader/disableLoader', LOADERS['LEAGUES'], { root: true });
            });
        });
    },
    fetchSeasonLeagues: function (_a) {
        var state = _a.state, commit = _a.commit;
        commit('loader/enableLoader', LOADERS['LEAGUES'], { root: true });
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(getSeasonLeagues, {
                variables: {
                    first: LEAGUES['QUERY_AMOUNT'],
                },
                fetchPolicy: 'no-cache',
            }))
                .then(function (_a) {
                var data = _a.data;
                commit('setUserLeagues', { data: _.map(data.leagueParticipantStatistics.edges, function (item) {
                        return {
                            node: __assign({}, item.node.league, { positionsMoved: item.node.positionsMoved, form: item.node.form, position: item.node.position, previousPosition: item.node.previousPosition }),
                            cursor: item.cursor
                        };
                    }) });
                resolve(data);
            })
                .catch(function (err) {
                reject(err);
            })
                .then(function () {
                commit('loader/disableLoader', LOADERS['LEAGUES'], { root: true });
            });
        });
    },
    fetchAdditionalSeasonLeagues: function (_a, after) {
        var state = _a.state, commit = _a.commit;
        commit('loader/enableLoader', LOADERS['LEAGUES'], { root: true });
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(getSeasonLeagues, {
                variables: {
                    first: LEAGUES['QUERY_AMOUNT'],
                    after: after
                },
                fetchPolicy: 'no-cache',
            }))
                .then(function (_a) {
                var data = _a.data;
                var leagues = _.map(data.leagueParticipantStatistics.edges, function (item) {
                    return {
                        node: __assign({}, item.node.league, { positionsMoved: item.node.positionsMoved, form: item.node.form, position: item.node.position }),
                        cursor: item.cursor
                    };
                });
                if (!_.isEmpty(leagues)) {
                    commit('addUserLeagues', leagues);
                }
                resolve(leagues);
            })
                .catch(function (err) {
                reject(err);
            })
                .then(function () {
                commit('loader/disableLoader', LOADERS['LEAGUES'], { root: true });
            });
        });
    },
    fetchStables: function (_a) {
        var commit = _a.commit;
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(getUserStablesForLeague, {
                variables: {
                    first: STABLE['QUERY_AMOUNT']
                },
                fetchPolicy: 'no-cache',
            }))
                .then(function (_a) {
                var data = _a.data;
                commit('setStables', { data: data.userStables.edges });
                commit('setCursor', { data: _.last(data.userStables.edges).cursor });
                resolve(data);
            })
                .catch(function (err) {
                reject(err);
            });
        });
    },
};
export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
};
