var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _ from 'lodash';
import { apolloClient } from 'App/config/apollo-client';
import { LOADERS, LEADERBOARD } from "App/config";
import { getOverallDataGraphQLQuery, getOverallAdditionalDataGraphQLQuery, getOverallAdditionalBeforeDataGraphQLQuery, getOverallUserStatisticGraphQLQuery } from "App/services/graphql/entity/overallStatistics";
import { getMonthlyDataGraphQLQuery, getMonthlyAdditionalDataGraphQLQuery, getMonthlyAdditionalBeforeDataGraphQLQuery, getMonthlyUserStatisticGraphQLQuery } from "App/services/graphql/entity/monthlyStatistics";
import Axios from 'axios';
var state = {
    overall: {
        paid: [],
        free: [],
        charity: []
    },
    monthly: {
        paid: [],
        free: [],
        charity: []
    },
    myStatistics: {
        overall: {
            paid: [],
            free: [],
            charity: []
        },
        monthly: {
            paid: [],
            free: [],
            charity: []
        },
    },
    statisticFilter: 'overall',
    search: '',
    activeSorting: 'points',
    sortConfig: {
        position: {
            name: "No.",
            type: "asc",
        },
        userStable_name: {
            name: "Silk / Stable name",
            type: "asc",
        },
        positionsMoved: {
            name: "Move",
            type: "asc",
        },
        points: {
            name: "Total",
            type: "desc"
        }
    },
    selectedUserStable: null,
    stablesType: "paid",
    myTopStable: false,
};
var getters = {
    getFormattedSortData: function (state) {
        return _.mapValues(state.sortConfig, function (item) {
            return item.type;
        });
    },
    getMyTopStable: function (state) {
        return state.myTopStable;
    },
    getMyStatistics: function (state, getters) {
        return state.myStatistics[getters.getStatisticType];
    },
    getActiveSortingObject: function (state, getters) {
        return _.pick(getters.getFormattedSortData, [state.activeSorting]);
    },
    getStatisticType: function (state) {
        return state.statisticFilter === 'overall' ? 'overall' : 'monthly';
    },
    getStablesType: function (state) {
        return state.stablesType;
    },
};
var mutations = {
    setMyTopStable: function (state, status) {
        state.myTopStable = status;
    },
    setMyStatistics: function (state, _a) {
        var type = _a.type, key = _a.key, data = _a.data;
        state.myStatistics[type][key] = data;
    },
    setStatisticFilter: function (state, type) {
        state.statisticFilter = type;
    },
    setSortType: function (state, _a) {
        var key = _a.key, type = _a.type;
        state.sortConfig[key].type = type;
    },
    setActiveSorting: function (state, key) {
        state.activeSorting = key;
    },
    setSelectedUserStable: function (state, id) {
        state.selectedUserStable = id;
    },
    setStablesType: function (state, type) {
        state.stablesType = type;
    },
    setSearch: function (state, value) {
        state.search = value;
    }
};
var actions = {
    setStatistic: function (_a, _b) {
        var state = _a.state, getters = _a.getters;
        var statistic = _b.statistic, key = _b.key;
        state[getters.getStatisticType][key] = statistic;
    },
    updateStatistic: function (_a, _b) {
        var state = _a.state, getters = _a.getters;
        var statistic = _b.statistic, key = _b.key, _c = _b.type, type = _c === void 0 ? 1 : _c;
        if (type) {
            state[getters.getStatisticType][key] = _.concat(state[getters.getStatisticType][key], statistic);
        }
        else {
            state[getters.getStatisticType][key] = _.concat(statistic, state[getters.getStatisticType][key]);
        }
    },
    fetchMyStatistics: function (_a) {
        var state = _a.state, commit = _a.commit, getters = _a.getters;
        var query = null;
        if (getters.getStatisticType === 'overall') {
            query = Object.assign(getOverallUserStatisticGraphQLQuery);
        }
        else {
            query = Object.assign(getMonthlyUserStatisticGraphQLQuery, {
                variables: {
                    date: state.statisticFilter
                }
            });
        }
        commit('loader/enableLoader', LOADERS['LEADER_BOARD'], { root: true });
        return new Promise(function (resolve, reject) {
            apolloClient.query(query)
                .then(function (res) {
                commit('setMyStatistics', {
                    type: getters.getStatisticType,
                    key: 'paid',
                    data: res.data.paid.edges,
                });
                commit('setMyStatistics', {
                    type: getters.getStatisticType,
                    key: 'free',
                    data: res.data.free.edges,
                });
                commit('setMyStatistics', {
                    type: getters.getStatisticType,
                    key: 'charity',
                    data: res.data.charity.edges,
                });
                resolve(res.data);
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
                reject(err);
            })
                .then(function () {
                commit('loader/disableLoader', LOADERS['LEADER_BOARD'], { root: true });
            });
        });
    },
    fetchOverallData: function (_a) {
        var state = _a.state, getters = _a.getters, commit = _a.commit, dispatch = _a.dispatch;
        dispatch('fetchMyStatistics');
        commit('loader/enableLoader', LOADERS['LEADER_BOARD'], { root: true });
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(getOverallDataGraphQLQuery, {
                variables: {
                    amount: LEADERBOARD['QUERY_AMOUNT'],
                    order: [getters.getActiveSortingObject],
                    userStable: state.selectedUserStable,
                    stableName: state.search
                }
            }))
                .then(function (_a) {
                var data = _a.data;
                dispatch('setStatistic', { statistic: data.paid.edges, key: 'paid' });
                dispatch('setStatistic', { statistic: data.free.edges, key: 'free' });
                dispatch('setStatistic', { statistic: data.charity.edges, key: 'charity' });
                resolve();
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
                reject(err);
            })
                .then(function () {
                commit('loader/disableLoader', LOADERS['LEADER_BOARD'], { root: true });
            });
        });
    },
    fetchMyTopStatistics: function (_a, _b) {
        var state = _a.state, getters = _a.getters, commit = _a.commit, dispatch = _a.dispatch;
        var myTopStatistic = _b.myTopStatistic, type = _b.type, middleware = _b.middleware;
        commit('loader/enableLoader', LOADERS['LEADER_BOARD'], { root: true });
        return new Promise(function (resolve, reject) {
            var order = state.sortConfig[state.activeSorting].type;
            var statisticType = state.statisticFilter === 'overall' ? 'overall' : state.statisticFilter.split(' ')[0];
            var queries = {
                overall: {
                    after: getOverallAdditionalDataGraphQLQuery,
                    before: getOverallAdditionalBeforeDataGraphQLQuery
                },
                monthly: {
                    after: getMonthlyAdditionalDataGraphQLQuery,
                    before: getMonthlyAdditionalBeforeDataGraphQLQuery
                }
            };
            var getType = state.statisticFilter === 'overall' ? 'overallStatistics' : 'monthlyStatistics';
            var query = queries[getters.getStatisticType];
            var date = statisticType === 'overall' ? undefined : state.statisticFilter;
            Axios.get("/api/leaderboard-stable-position/" + myTopStatistic.node.userStable._id + "/" + statisticType, {
                params: {
                    type: state.stablesType,
                    sort: state.activeSorting,
                    order: order,
                }
            })
                .then(function (numRequest) {
                var position = btoa((parseInt(numRequest.data) - 1).toString());
                apolloClient.query(Object.assign(query.after, {
                    variables: {
                        amount: LEADERBOARD['QUERY_AMOUNT'],
                        order: [getters.getActiveSortingObject],
                        userStable: state.selectedUserStable,
                        stableName: state.search,
                        type: type,
                        date: date,
                        after: position
                    }
                }))
                    .then(function (_a) {
                    var data = _a.data;
                    dispatch('setStatistic', { statistic: [myTopStatistic].concat(data[getType].edges), key: type });
                    if (middleware) {
                        middleware();
                    }
                })
                    .catch(function (err) {
                    commit('updateErrorMessage', err, { root: true });
                    reject(err);
                })
                    .then(function () {
                    apolloClient.query(Object.assign(query.before, {
                        variables: {
                            amount: LEADERBOARD['QUERY_AMOUNT'],
                            order: [getters.getActiveSortingObject],
                            userStable: state.selectedUserStable,
                            stableName: state.search,
                            type: type,
                            date: date,
                            before: position
                        }
                    }))
                        .then(function (_a) {
                        var data = _a.data;
                        dispatch('updateStatistic', { statistic: data[getType].edges, key: type, type: 0 });
                        resolve();
                    })
                        .catch(function (err) {
                        commit('updateErrorMessage', err, { root: true });
                        reject(err);
                    })
                        .then(function () {
                        commit('loader/disableLoader', LOADERS['LEADER_BOARD'], { root: true });
                    });
                });
            });
        });
    },
    fetchMonthData: function (_a, date) {
        var state = _a.state, commit = _a.commit, getters = _a.getters, dispatch = _a.dispatch;
        commit('loader/enableLoader', LOADERS['LEADER_BOARD'], { root: true });
        dispatch('fetchMyStatistics');
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(getMonthlyDataGraphQLQuery, {
                variables: {
                    amount: LEADERBOARD['QUERY_AMOUNT'],
                    order: [getters.getActiveSortingObject],
                    date: date,
                    userStable: state.selectedUserStable,
                    stableName: state.search
                }
            }))
                .then(function (_a) {
                var data = _a.data;
                dispatch('setStatistic', { statistic: data.paid.edges, key: 'paid' });
                dispatch('setStatistic', { statistic: data.free.edges, key: 'free' });
                dispatch('setStatistic', { statistic: data.charity.edges, key: 'charity' });
                resolve();
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
                reject(err);
            })
                .then(function () {
                commit('loader/disableLoader', LOADERS['LEADER_BOARD'], { root: true });
            });
        });
    },
    fetchAdditionalOverallData: function (_a, _b) {
        var state = _a.state, commit = _a.commit, dispatch = _a.dispatch;
        var type = _b.type, after = _b.after, before = _b.before, order = _b.order;
        var addTo = {};
        var query = getOverallAdditionalDataGraphQLQuery;
        if (before) {
            addTo.before = before;
            query = getOverallAdditionalBeforeDataGraphQLQuery;
        }
        else {
            addTo.after = after;
        }
        commit('loader/enableLoader', LOADERS['LEADER_BOARD'], { root: true });
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(query, {
                variables: __assign({ amount: LEADERBOARD['QUERY_AMOUNT'], type: type, order: [order], stableName: state.search }, addTo)
            }))
                .then(function (_a) {
                var data = _a.data;
                dispatch('updateStatistic', { statistic: data.overallStatistics.edges, key: type, type: addTo.before ? 0 : 1 });
                resolve(_.isEmpty(data.overallStatistics.edges));
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
                reject(err);
            })
                .then(function () {
                commit('loader/disableLoader', LOADERS['LEADER_BOARD'], { root: true });
            });
        });
    },
    fetchAdditionalMonthlyData: function (_a, _b) {
        var state = _a.state, commit = _a.commit, dispatch = _a.dispatch;
        var type = _b.type, after = _b.after, before = _b.before, date = _b.date, order = _b.order;
        var addTo = {};
        var query = getMonthlyAdditionalDataGraphQLQuery;
        if (before) {
            addTo.before = before;
            query = getMonthlyAdditionalBeforeDataGraphQLQuery;
        }
        else {
            addTo.after = after;
        }
        commit('loader/enableLoader', LOADERS['LEADER_BOARD'], { root: true });
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(query, {
                variables: __assign({ amount: LEADERBOARD['QUERY_AMOUNT'], type: type, date: date, order: [order], stableName: state.search }, addTo)
            }))
                .then(function (_a) {
                var data = _a.data;
                dispatch('updateStatistic', { statistic: data.monthlyStatistics.edges, key: type, type: addTo.before ? 0 : 1 });
                resolve(_.isEmpty(data.monthlyStatistics.edges));
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
                reject(err);
            })
                .then(function () {
                commit('loader/disableLoader', LOADERS['LEADER_BOARD'], { root: true });
            });
        });
    }
};
export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions
};
