import Vue from 'vue'
const   inputActiveClass = 'active',
        inputFocusedClass = 'focused',
        removeClassList = (el, className) => el.closest('.form-group').classList.remove(className),
        addClassList = (el, className) => el.closest('.form-group').classList.add(className)
 
Vue.directive('materializeInput', {
        inserted: (el) => {
            const onAnimationStart = ({target, animationName}) => {
                switch (animationName) {
                    case 'onAutoFillStart':
                        return addClassList(target, inputActiveClass, true);
                    case 'onAutoFillCancel':
                        if (!el.value && !el.placeholder) {
                            return removeClassList(target, inputActiveClass);
                        }
                }
            };
            const onFocus = () => {
                return addClassList(el, inputFocusedClass);
            };
            const onBlur = () => {
                removeClassList(el, inputFocusedClass);
                if (!el.value) {
                    return removeClassList(el, inputActiveClass);
                }
            };

            const onInput = () => {
                if(el.value){
                    return addClassList(el, inputActiveClass);
                }

            };

            if (el.value) {
                addClassList(el, inputActiveClass);
            }

            el.addEventListener('animationstart', onAnimationStart, false);
            el.addEventListener('focus', onFocus, false);
            el.addEventListener('blur', onBlur, false);
            el.addEventListener('input', onInput, false);

        },
        update: (el) => {
            if (el.value) {
                return addClassList(el, inputActiveClass);
            }
            return removeClassList(el, inputActiveClass);
        }
    }
);
