import QueryBuilder from "App/services/graphql/base-query-builder";
/***** Queries *****/
import { getStablesByName, getPendingUserStablesQuery, getFreeUserStableQuery, getAdditionalPaidUserStablesQuery, getPickTenUserStableQuery, getUserStableQuery, getAdditionalOverallLeagueUserStablesQuery, getUserStablesByHorseQuery, getUserStablesByHorseAdditionalQuery, getUserSeasonStableQuery, getUserStablesByPermQuery, getPaidUserStablesGroupedByPermQuery, getUserPaidStablesInSeasonQuery, getUserFreeStablesInSeasonQuery, getUserAdditionalPaidStablesInSeasonQuery } from 'App/services/graphql/queries/userStable';
/***** Mutations *****/
import { deleteStableMutation, createStableMutation, updateStableMutation, updateHorseInStableMutation, createPurchaseTransactionMutation, replaceHorseMutation, createHorseTransferMutation, createHorseBatchTransferMutation, createFreeStableMutation } from 'App/services/graphql/mutations/userStable';
import horseFragment from "App/services/graphql/fragments/horseFragment";
import trainerFragment from "App/services/graphql/fragments/trainerFragment";
import ownerFragments from 'App/services/graphql/fragments/ownerFragment';
import raceParticipantFragment from "App/services/graphql/fragments/raceParticipantsFragment";
import raceFragment from "App/services/graphql/fragments/raceFragment";
import meetingFragment from "App/services/graphql/fragments/meetingFragment";
import userStableFragment from 'App/services/graphql/fragments/userStableFragment';
import poolRunnersFragment from "App/services/graphql/fragments/poolRunnersFragment";
/***** Queries Builder*****/
export var getPendingUserStablesGraphQLQuery = new QueryBuilder([getPendingUserStablesQuery], [userStableFragment], []).generateQuery();
export var getPaidUserStablesGroupedByPermQueryQLQuery = new QueryBuilder([getPaidUserStablesGroupedByPermQuery], [userStableFragment], [{
        $first: "Int",
        $name: "String"
    }]).generateQuery();
export var pickTenUserStableGraphQLQuery = new QueryBuilder([getPickTenUserStableQuery], [horseFragment, trainerFragment, ownerFragments], [{
        $id: "ID!"
    }
]).generateQuery();
export var getAdditionalPaidUserStablesGraphQLQuery = new QueryBuilder([getAdditionalPaidUserStablesQuery], [userStableFragment], [{
        $first: "Int",
        $after: "String",
        $name: "String"
    }]).generateQuery();
export var getFreeUserStablesGraphQlQuery = new QueryBuilder([getFreeUserStableQuery], [userStableFragment], []).generateQuery();
export var getUserStableGraphQlQuery = new QueryBuilder([getUserStableQuery], [trainerFragment, ownerFragments, horseFragment, raceParticipantFragment, raceFragment, meetingFragment, poolRunnersFragment], [
    {
        $id: "ID!"
    }
]).generateQuery();
export var getAdditionalOverallLeagueUserStables = new QueryBuilder([getAdditionalOverallLeagueUserStablesQuery], [], [{
        $first: "Int",
        $after: "String"
    }]).generateQuery();
export var getUserStablesByHorseGraphQLQuery = new QueryBuilder([getUserStablesByHorseQuery], [userStableFragment], [{
        $horseId: "Int",
        $name: "String",
        $first: "Int",
    }]).generateQuery();
export var getUserStablesByHorseAdditionalGraphQLQuery = new QueryBuilder([getUserStablesByHorseAdditionalQuery], [userStableFragment], [{
        $horseId: "Int",
        $name: "String",
        $first: "Int",
        $after: "String"
    }]).generateQuery();
export var getUserSeasonStable = new QueryBuilder([getUserSeasonStableQuery], [trainerFragment,
    ownerFragments,
    horseFragment,
    raceParticipantFragment,
    raceFragment,
    meetingFragment], [
    {
        $id: "ID!"
    }
]).generateQuery();
export var getUserStablesForLeaderboardGraphQuery = new QueryBuilder([getStablesByName], [], [
    {
        $type: "String",
        $name: "String"
    }
]).generateQuery();
export var getUserStablesByPermGraphQuery = new QueryBuilder([getUserStablesByPermQuery], [userStableFragment], [
    {
        $perm: "String",
        $first: "Int",
        $after: "String",
        $order: "[UserStableFilter_order]",
    }
]).generateQuery();
/***** Mutations Builder*****/
export var deleteStableGraphQLMutation = new QueryBuilder([deleteStableMutation], [], [{
        $id: "ID!",
    }]).generateMutation();
export var purchaseTransactionStableGraphQLMutation = new QueryBuilder([createPurchaseTransactionMutation], [], [{
        $userStables: "Iterable!",
    }]).generateMutation();
export var createStableGraphQLMutation = new QueryBuilder([createStableMutation], [horseFragment, userStableFragment], [{
        $name: "String!",
        $type: "String!",
        $horses: "Iterable",
    }]).generateMutation();
export var updateHorseInStableGraphQLMutation = new QueryBuilder([updateHorseInStableMutation], [horseFragment], [{
        $userStable: "String!",
        $horses: "Iterable",
        $name: "String!"
    }]).generateMutation();
export var updateStableGraphQLMutation = new QueryBuilder([updateStableMutation], [], [{
        $id: "ID!",
        $name: "String!",
        $pinned: "Boolean!",
    }]).generateMutation();
export var replaceHorseGraphQLMutation = new QueryBuilder([replaceHorseMutation], [horseFragment, trainerFragment, ownerFragments], [{
        $currentHorse: "String!",
        $newHorse: "String!"
    }]).generateMutation();
export var createHorseTransferGraphQLMutation = new QueryBuilder([createHorseTransferMutation], [], [{
        $userStable: "String!",
        $oldHorse: "String!",
        $newHorse: "String!"
    }]).generateMutation();
export var createBatchHorseTransferGraphQLMutation = new QueryBuilder([createHorseBatchTransferMutation], [], [{
        $oldHorse: "String!",
        $newHorse: "String!"
    }]).generateMutation();
export var createFreeStableGraphQLMutation = new QueryBuilder([createFreeStableMutation], [], [{
        $userStable: "String"
    }]).generateMutation();
export var getUserStablesInSeasonQueryGraphQLQuery = new QueryBuilder([getUserFreeStablesInSeasonQuery, getUserPaidStablesInSeasonQuery], [userStableFragment], [{
        $first: "Int",
    }]).generateQuery();
export var getUserAdditionalPaidStablesInSeasonQueryGraphQLQuery = new QueryBuilder([getUserAdditionalPaidStablesInSeasonQuery], [userStableFragment], [{
        $first: "Int",
        $after: "String",
        $name: "String!",
        $order: "[UserStableFilter_order]",
    }]).generateQuery();
