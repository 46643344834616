import _ from 'lodash';
import Vue from 'vue';
import HorseCacheService from "App/services/cache/HorseCacheService";
import { apolloClient } from 'App/config/apollo-client';
import { getHorsesGraphQLQuery } from "App/services/graphql/entity/horse";
import { horseBonusRacesGraphQLQuery } from "App/services/graphql/entity/horseBonusRace";
import { pickTenUserStableGraphQLQuery, replaceHorseGraphQLMutation } from "App/services/graphql/entity/userStable";
import { HORSE_LIST, STABLE } from "App/config";
import QueryBuilder from "App/services/graphql/base-query-builder";
var state = {
    horses: [],
    horseList: [],
    bonusRaces: [],
    pickTenStables: [],
    user: window.application.user,
    replaceHorse: {
        horseId: null,
        status: false
    },
};
var getters = {
    getHorses: function (state) { return !_.isEmpty(state.horses) && state.horses.edges; },
    getHorsesLength: function (state, getters) { return getters.getHorses && getters.getHorses.length; },
    getHorsesAges: function (state, getters) { return getters.getHorses && Array.from(new Set(getters.getHorses.map(function (_a) {
        var node = _a.node;
        return node.age;
    }))).sort(function (a, b) { return a - b; }); },
    getBonusRaces: function (state) { return !_.isEmpty(state.bonusRaces) && state.bonusRaces; },
    getShortListedHorses: function (state, getters) { return _.filter(getters.getHorses, function (horse) {
        return horse.node[HORSE_LIST['SHORT_LIST_OPTION']];
    }); },
    getShortListedHorsesLength: function (state, getters) { return getters.getShortListedHorses && getters.getShortListedHorses.length; },
    getHorsesAddedToStable: function (state, getters) { return _.filter(getters.getHorses, function (horse) {
        return horse.node[HORSE_LIST['STABLE_OPTION']];
    }); },
    getHorsesAddedToStableLength: function (state, getters) { return getters.getHorsesAddedToStable && getters.getHorsesAddedToStable.length; },
    getIsStableCompleted: function (state, getters) { return getters.getHorsesAddedToStableLength >= STABLE['MAX_LENGTH']; },
    getHorsesStableId: function (state, getters) { return _.map(getters.getHorsesAddedToStable, function (horse) {
        return horse.node.id;
    }); },
    getPinned: function (state, getters) { return _.filter(getters.getHorses, function (horse) {
        return horse.node[HORSE_LIST['PIN_OPTION']];
    }); },
    getPinnedLength: function (state, getters) { return getters.getPinned && getters.getPinned.length; },
    getUsedHorses: function (state, getters) { return _.filter(getters.getHorses, function (horse) {
        return horse.node.numberOfUsesInUserStables;
    }); },
    getUsedHorsesLength: function (state, getter) { return getter.getUsedHorses.length; },
    getReplacementHorseId: function (state) { return state.replaceHorse.horseId; },
    getReplacementStatus: function (state) { return state.replaceHorse.status; }
};
var mutations = {
    setRaceParticipants: function (state, _a) {
        var horses = _a.horses, bonusRaces = _a.bonusRaces;
        state.horses = horses;
        state.horseList = horses.edges;
        state.bonusRaces = bonusRaces.edges;
    },
    setHorses: function (state, _a) {
        var horses = _a.horses;
        state.horses = horses;
        state.horseList = horses.edges;
    },
    setBonusRaces: function (state, _a) {
        var bonusRaces = _a.bonusRaces;
        state.bonusRaces = bonusRaces.edges;
    },
    mutatePermsInHorseList: function (state, _a) {
        var horseId = _a.horseId, value = _a.value, _b = _a.key, key = _b === void 0 ? HORSE_LIST['PERM_OPTION'] : _b;
        var index = _.findIndex(state.horses.edges, function (horse) { return horse.node.id === horseId; });
        if (index === -1)
            return;
        Vue.set(state.horses.edges[index].node, key, value);
    },
    mutateHorseList: function (state, _a) {
        var index = _a.index, key = _a.key, value = _a.value, horseId = _a.horseId;
        if (horseId) {
            var fitIndex = state.horses.edges.findIndex(function (_a) {
                var node = _a.node;
                var _id = (node || {})._id;
                return _id === horseId;
            });
            Vue.set(state.horses.edges[fitIndex].node, key, value);
            return;
        }
        Vue.set(state.horses.edges[index].node, key, value);
    },
    updatePrintedList: function (state, data) {
        state.horseList = data;
    },
    pickTenAddHorse: function (state, horse) {
        var isHorsesAdded = _.find(state.pickTenStables, function (item) {
            return item && item._id === horse._id;
        });
        if (isHorsesAdded)
            return;
        var index = _.findIndex(state.pickTenStables, function (item) {
            return !item;
        });
        if (index !== -1) {
            state.pickTenStables.splice(index, 1, horse);
            return;
        }
        state.pickTenStables.push(horse);
    },
    pickTenAddHorses: function (state, horses) {
        state.pickTenStables = horses;
    },
    pickTenRemoveHorse: function (state, _a) {
        var id = _a.id, completely = _a.completely;
        var index = _.findIndex(state.pickTenStables, function (item) {
            return item && item.id == id;
        });
        if (index === -1)
            return;
        if (!completely) {
            state.pickTenStables.splice(index, 1, null);
            return;
        }
        state.pickTenStables.splice(index, 1);
    },
    setReplaceHorse: function (state, _a) {
        var horseId = _a.horseId, status = _a.status;
        if (!_.isUndefined(horseId))
            state.replaceHorse.horseId = horseId;
        if (!_.isUndefined(status))
            state.replaceHorse.status = status;
    }
};
var actions = {
    fetchHorses: function (_a, cache) {
        var commit = _a.commit;
        if (cache === void 0) { cache = "cache-first"; }
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(getHorsesGraphQLQuery, {
                fetchPolicy: cache
            }))
                .then(function (_a) {
                var data = _a.data;
                commit('setHorses', { horses: data.horses });
                resolve(data);
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
            });
        });
    },
    fetchBonusRaces: function (_a) {
        var commit = _a.commit;
        return new Promise(function (resolve, reject) {
            apolloClient.query(horseBonusRacesGraphQLQuery)
                .then(function (_a) {
                var data = _a.data;
                commit('setBonusRaces', { bonusRaces: data.bonusRaces });
                resolve(data);
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
            });
        });
    },
    horseListManipulation: function (_a, _b) {
        var state = _a.state, commit = _a.commit;
        var id = _b.id, value = _b.value, parameters = _b.parameters;
        return new Promise(function (resolve, reject) {
            var index = _.findIndex(state.horses.edges, function (item) {
                return item.node.id === id;
            });
            if (index === -1)
                return;
            if (_.includes(parameters.keys, HORSE_LIST['STABLE_OPTION'])) {
                commit('mutateHorseList', { index: index, key: HORSE_LIST['STABLE_OPTION'], value: value });
                if (value) {
                    var horse = state.horses.edges[index].node;
                    commit('pickTenAddHorse', {
                        id: horse.id,
                        _id: horse._id,
                        name: horse.name,
                        silkPath: horse.silkPath,
                        trainer: horse.trainer && horse.trainer.name,
                        age: horse.age,
                        owner: horse.owner && horse.owner.name,
                        horseForm: horse.horseForm || horse.form,
                        horseRating: horse.horseRating || horse.rating
                    });
                }
                else {
                    commit('pickTenRemoveHorse', { id: state.horses.edges[index].node.id });
                }
            }
            if (!parameters.mutations) {
                resolve();
                return;
            }
            var queryObject = new QueryBuilder(parameters.mutations.query, [], [parameters.mutations.params]).generateMutation();
            apolloClient.mutate(Object.assign(queryObject, {
                variables: parameters.mutations.variables,
            }))
                .then(function () {
                _.forEach(parameters.keys, function (key) {
                    commit('mutateHorseList', { index: index, key: key, value: value });
                });
                resolve();
            })
                .catch(function (err) {
                reject(err);
            });
        });
    },
    deleteHorsesAddedToStable: function (_a, all) {
        var state = _a.state, commit = _a.commit;
        if (all === void 0) { all = true; }
        _.forEach(state.horses.edges, function (item) {
            if (all || !item.node[HORSE_LIST['PIN_OPTION']]) {
                Vue.set(item.node, HORSE_LIST['STABLE_OPTION'], false);
                if (all) {
                    commit('pickTenRemoveHorse', { id: item.node.id, completely: true });
                }
                else {
                    commit('pickTenRemoveHorse', item.node.id);
                }
            }
        });
    },
    fetchUserStable: function (_a, id) {
        var state = _a.state, dispatch = _a.dispatch;
        return new Promise(function (resolve, reject) {
            apolloClient.query(Object.assign(pickTenUserStableGraphQLQuery, {
                variables: {
                    id: id
                },
                fetchPolicy: 'no-cache'
            })).then(function (_a) {
                var data = _a.data;
                dispatch('deleteHorsesAddedToStable', false);
                resolve(data);
            }).catch(function (err) {
                reject(err);
            });
        });
    },
    replaceHorseInStables: function (_a, _b) {
        var state = _a.state, commit = _a.commit, dispatch = _a.dispatch;
        var currentHorseId = _b.currentHorseId, newHorseId = _b.newHorseId;
        return new Promise(function (resolve, reject) {
            apolloClient.mutate(Object.assign(replaceHorseGraphQLMutation, {
                variables: {
                    currentHorse: currentHorseId,
                    newHorse: newHorseId
                },
                update: function (store, _a) {
                    var horseReplacement = _a.data.makeHorseReplacement.horseReplacement;
                    var horseCache = new HorseCacheService(store, getHorsesGraphQLQuery);
                    horseCache.updateHorseById(currentHorseId, horseReplacement.currentHorse);
                    horseCache.updateHorseById(newHorseId, horseReplacement.newHorse);
                }
            }))
                .then(function (_a) {
                var data = _a.data;
                dispatch('fetchHorses');
                resolve({ data: data });
            })
                .catch(function (err) {
                commit('updateErrorMessage', err, { root: true });
                reject(err);
            })
                .then(function () {
                resolve();
            });
        });
    },
};
export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
};
