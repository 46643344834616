var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { mapActions, mapGetters, mapState } from "vuex";
import moment from 'moment';
// Platform components
import PlatformComponents from './PlatformComponents';
// Platform widgets
import PlatformWidgets from './PlatformWidgets';
import MenuMixin from 'App/mixins/MenuMixin';
import LoadErrorHandler from 'App/mixins/LoadErrorHandler';
//import MixTracking from 'App/services/mix-tracking';
import UrlPointer from "./mixins/UrlPointer";
import PostMessageHandler from "./services/post-message-handler";
var SocialSharing = require('vue-social-sharing');
import { ROUTES, STABLE } from 'App/config';
import { apolloProvider } from './config/apollo-client';
import store from './store';
import router from './router';
import './directives/MaterializeInput';
Vue.use(VueApollo);
Vue.use(PlatformComponents);
Vue.use(PlatformWidgets);
Vue.use(SocialSharing);
Vue.prototype.$translations = window.application.translations;
Vue.prototype.$t = window.application.translations;
window.postMessageHandler = new PostMessageHandler(window.application.origin);
// window.mixTrack = new MixTracking();
// mixTrack.init();
window.dataLayer = window.dataLayer || [];
var GTM = require('./lib/GTM');
window.gtm = new GTM();
document.addEventListener('click', function (e) {
    if (e.target && e.target.className == 'close') {
        var modalId = e.target.closest('.modal').id;
        if (modalId) {
            window.gtm.popUpInteraction(modalId, 'closed');
        }
    }
});
Vue.filter('priceToDecimal', function (value) {
    var currency = STABLE['CURRENCY'];
    if (!value)
        return '';
    if (currency != '€')
        return value;
    return value.toFixed(2);
});
new Vue({
    delimiters: ['${', '}'],
    store: store,
    router: router,
    apolloProvider: apolloProvider,
    mixins: [MenuMixin, UrlPointer, LoadErrorHandler],
    data: function () { return ({
        dateNowFormatted: null,
    }); },
    computed: __assign({}, mapGetters({
        'getSidebarMobileState': 'sidebar/getSidebarMobileState'
    }), mapState({
        'errorMessages': function (state) { return state.errorMessages; },
    }), { isMiniGame: function () {
            var hostname = window.location.hostname;
            var params = new URLSearchParams(document.location.search);
            // check testing mode
            if (params.get("test-minigame")) {
                return params.get("test-minigame") === "true";
            }
            return hostname.includes('minigame.tote');
        } }),
    methods: __assign({}, mapActions({
        'showBatchTransferRequestNotifications': 'notification/showBatchTransferRequestNotifications',
    }), { isIOSDevice: function () {
            if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
                document.body.classList.add('ios-device');
            }
        },
        showLoginPopUp: function (e) {
            if (!window.application.user) {
                e.preventDefault();
                postMessageHandler.sendMessage({ "type": "LOGIN_POPUP_OPEN" });
            }
        },
        fetchDateNow: function () {
            this.dateNowFormatted = moment().format('HH:mm:ss dddd DD MMMM YYYY');
        },
        redirectControll: function () {
            if (window.application.isAnonymousStableCreated) {
                this.$router.push({ name: ROUTES['PICK_TEN_LIST'] });
            }
        } }),
    created: function () {
        var _this = this;
        this.isIOSDevice();
        setInterval(function () { return _this.fetchDateNow(); }, 1000);
        this.showBatchTransferRequestNotifications();
        this.redirectControll();
    },
}).$mount('#app');
