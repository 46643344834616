import QueryBuilder from "App/services/graphql/base-query-builder";
import userStableFragment from "App/services/graphql/fragments/userStableFragment";
/***** Queries *****/
import { getCurrentUserLeaguesSeasonQuery, getCurrentUserLeaguesQuery, getAdditionalCurrentUserLeaguesQuery, getCurrentUserLeagueQuery, getLeagueStableInSeasonQuery, getLeagueByPinQuery } from "../queries/leagues";
import { getUserStablesForLeagueQuery } from "App/services/graphql/queries/userStable";
/***** Mutations *****/
import { createLeagueMutation, addStableForLeagueMutation } from "App/services/graphql/mutations/leagues";
/***** Queries Builder*****/
export var getCurrentUserLeague = new QueryBuilder([getCurrentUserLeagueQuery], [userStableFragment], [
    {
        $id: "String!",
        $first: "Int",
        $after: "String"
    }
]).generateQuery();
export var getAdditionalCurrentUserLeagues = new QueryBuilder([getAdditionalCurrentUserLeaguesQuery], [], [
    {
        $first: "Int",
        $after: "String!"
    }
]).generateQuery();
export var getLeagueStableInSeasonGraphQLQuery = new QueryBuilder([getLeagueStableInSeasonQuery], [userStableFragment], [
    {
        $league: "String",
        $first: "Int",
        $after: "String",
        $order: "[LeagueParticipantStatisticFilter_order]"
    }
]).generateQuery();
export var getLeagueByPin = new QueryBuilder([getLeagueByPinQuery], [], [
    {
        $pin: "String!"
    }
]).generateQuery();
export var getLeagues = new QueryBuilder([getCurrentUserLeaguesQuery], [], [
    {
        $first: "Int"
    }
]).generateQuery();
export var getSeasonLeagues = new QueryBuilder([getCurrentUserLeaguesSeasonQuery], [], [
    {
        $first: "Int",
        $after: "String",
    }
]).generateQuery();
export var getUserStablesForLeague = new QueryBuilder([getUserStablesForLeagueQuery], [], [
    {
        $first: "Int"
    }
]).generateQuery();
export var getLeaguesInitialData = new QueryBuilder([getCurrentUserLeaguesQuery], [], [
    {
        $first: "Int"
    }
]).generateQuery();
/***** Mutations Builder*****/
export var addStableForLeague = new QueryBuilder([addStableForLeagueMutation], [], [
    {
        $stable: "String!",
        $league: "String!"
    }
]).generateMutation();
export var createLeague = new QueryBuilder([createLeagueMutation], [], [
    {
        $name: "String!"
    }
]).generateMutation();
