var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/*** libs ***/
import _ from "lodash";
import { HORSE_LIST, PERMS, STABLE, LOADERS, REFETCHED_QUERIES, POPUP_MENU_WIDTH_TRIGGERING } from "App/config";
/*** GraphQl ***/
import { apolloClient } from "App/config/apollo-client";
import { getCurrentPermGraphQLQuery, createPermGraphQLMutation, updatePermGraphQLMutation, createPermBoxGraphQLMutation, updatePermBoxGraphQLMutation, deletePermBoxGraphQLMutation, addItemsToPermBoxGraphQLMutation, purchasePermGraphQLMutation } from "App/services/graphql/entity/userPerm";
import { utilGetPermBoxStablesNumber } from "App/utils/perm-box-stables-number";
var state = {
    currentPerm: {
        id: "",
        name: "",
        boxes: { edges: [] }
    },
    activeBoxId: "",
    replacedHorseId: "",
    boxFormCreationMode: false,
    yardFormIsVisible: false,
    permBoxFormIsVisible: false,
    tempDeletedHorsesData: [],
};
var getters = {
    getCurrentPermId: function (state) {
        return state.currentPerm.id;
    },
    getPermBoxes: function (state) {
        return _.map(state.currentPerm.boxes.edges, "node");
    },
    getPermBoxesLength: function (state, getters) {
        return getters.getPermBoxes.length;
    },
    getPermBoxesPositions: function (state, getters) {
        var boxes = getters.getPermBoxes;
        return _.map(boxes, 'positions');
    },
    getPermBoxesPositionsSum: function (state, getters) {
        var boxes = getters.getPermBoxes;
        return boxes.reduce(function (num, box) {
            num += box.positions;
            return num;
        }, 0);
    },
    getPermPositionsAvailable: function (state, getters) {
        return PERMS["POSITIONS_MAX"] - getters.getPermBoxesPositionsSum;
    },
    getIsPermPositionsFull: function (state, getters) {
        return getters.getPermPositionsAvailable <= 0;
    },
    getPermStablesTotalNumber: function (state, getters) {
        var boxes = getters.getPermBoxes;
        return boxes.reduce(function (total, box) {
            var permBoxHorsesLength = box.items.edges.length;
            var permBoxPositions = box.positions;
            var permBoxStablesNumber = utilGetPermBoxStablesNumber(permBoxHorsesLength, permBoxPositions);
            return (total *= permBoxStablesNumber);
        }, 1);
    },
    getPermStablesTotalPrice: function (state, getters) {
        return (STABLE["PRICE"] * getters.getPermStablesTotalNumber).toFixed(2);
    },
    getActiveBoxIndex: function (state, getters) {
        var boxes = getters.getPermBoxes;
        var activeBoxId = state.activeBoxId;
        return _.findIndex(boxes, function (box) { return box.id === activeBoxId; });
    },
    getActiveBox: function (state, getters) {
        var boxes = getters.getPermBoxes;
        var index = getters.getActiveBoxIndex;
        return boxes[index];
    },
    getHorsesAddedToPerm: function (state, getters, rootState, rootGetters) {
        return _.filter(rootGetters["game/getHorses"], function (horse) {
            return horse.node[HORSE_LIST["PERM_OPTION"]];
        });
    },
    getHorsesPreAddedToPerm: function (state, getters) {
        return _.filter(getters.getHorsesAddedToPerm, function (horse) { return horse.node[HORSE_LIST["PERM_OPTION"]] === true; });
    },
    getIsEditingMode: function (state) {
        return !!state.activeBoxId;
    },
    getTempDeletedHorses: function (state) {
        return _.map(state.tempDeletedHorsesData, function (item) {
            return item.horseId;
        });
    }
};
var mutations = {
    setCurrentPerm: function (state, perm) {
        state.currentPerm = perm;
    },
    setPermId: function (state, permId) {
        state.currentPerm.id = permId;
    },
    setPermName: function (state, permName) {
        state.currentPerm.name = permName;
    },
    setPermSilk: function (state, silk) {
        state.currentPerm.silk = silk;
    },
    addStateBox: function (state, permBox) {
        var boxes = state.currentPerm.boxes.edges;
        boxes.push({ node: permBox });
    },
    updateStateBox: function (state, permBox) {
        var id = permBox.id;
        var boxes = state.currentPerm.boxes.edges;
        var index = _.findIndex(boxes, function (box) { return box.node.id === id; });
        boxes.splice(index, 1, { node: permBox });
    },
    deleteStateBox: function (state, permBox) {
        var id = permBox.id;
        var edges = state.currentPerm.boxes.edges;
        state.currentPerm.boxes.edges = edges.filter(function (box) { return box.node.id !== id; });
    },
    resetCurrentPerm: function (state) {
        state.currentPerm = {
            id: "",
            name: "",
            boxes: { edges: [] }
        };
    },
    setActiveBox: function (state, id) {
        if (id === void 0) { id = ""; }
        state.activeBoxId = id;
    },
    setReplacedHorse: function (state, horseId) {
        if (horseId === void 0) { horseId = ""; }
        state.replacedHorseId = horseId;
    },
    /** perm state togglers **/
    toggleYardFormVisibility: function (state, isVisible) {
        state.yardFormIsVisible = isVisible;
    },
    togglePermFormVisibility: function (state, isVisible) {
        state.permBoxFormIsVisible = isVisible;
    },
    toggleBoxFormCreationMode: function (state, isFormCreationState) {
        state.boxFormCreationMode = isFormCreationState;
    },
    addTempDeletedHorsesData: function (state, horse) {
        state.tempDeletedHorsesData.push(horse);
    },
    removeTempDeletedHorsesData: function (state, boxId) {
        state.tempDeletedHorsesData = _.filter(state.tempDeletedHorsesData, function (item) {
            return item.boxId !== boxId;
        });
    }
};
var actions = {
    /** perm GraphQL **/
    fetch: function (_a) {
        var commit = _a.commit;
        commit("loader/enableLoader", LOADERS["PERM"], { root: true });
        return new Promise(function (resolve, reject) {
            apolloClient
                .query(Object.assign(getCurrentPermGraphQLQuery, {
                fetchPolicy: 'no-cache',
            }))
                .then(function (_a) {
                var data = _a.data;
                var currentPerm = data.currentPerm;
                if (currentPerm) {
                    commit("setCurrentPerm", currentPerm);
                }
                else {
                    commit('resetCurrentPerm');
                }
                resolve(currentPerm);
            })
                .catch(function (err) {
                commit("updateErrorMessage", err, { root: true });
                reject(err);
            })
                .then(function () {
                commit("loader/disableLoader", LOADERS["PERM"], {
                    root: true
                });
            });
        });
    },
    createPerm: function (_a, permName) {
        var commit = _a.commit;
        commit("loader/enableLoader", LOADERS["PERM"], { root: true });
        return new Promise(function (resolve, reject) {
            apolloClient
                .mutate(Object.assign(createPermGraphQLMutation, {
                variables: {
                    name: permName
                }
            }))
                .then(function (_a) {
                var createPerm = _a.data.createPerm;
                var perm = createPerm && createPerm.perm;
                commit("setPermId", perm.id);
                commit("setPermName", perm.name);
                resolve();
            })
                .catch(function (err) {
                commit("updateErrorMessage", err, { root: true });
                reject(err);
            })
                .then(function () {
                commit("loader/disableLoader", LOADERS["PERM"], {
                    root: true
                });
            });
        });
    },
    updatePerm: function (_a, permName) {
        var commit = _a.commit, state = _a.state;
        commit("loader/enableLoader", LOADERS["PERM"], { root: true });
        var id = state.currentPerm.id;
        return new Promise(function (resolve, reject) {
            apolloClient
                .mutate(Object.assign(updatePermGraphQLMutation, {
                variables: {
                    id: id,
                    name: permName
                }
            }))
                .then(function (_a) {
                var updatePerm = _a.data.updatePerm;
                var perm = updatePerm && updatePerm.perm;
                commit("setPermName", perm.name);
                resolve();
            })
                .catch(function (err) {
                commit("updateErrorMessage", err, { root: true });
                reject(err);
            })
                .then(function () {
                commit("loader/disableLoader", LOADERS["PERM"], {
                    root: true
                });
            });
        });
    },
    purchasePerm: function (_a, permName) {
        var getters = _a.getters, commit = _a.commit, state = _a.state, dispatch = _a.dispatch, rootGetters = _a.rootGetters;
        commit("loader/enableLoader", LOADERS["PERM"], { root: true });
        var id = state.currentPerm.id;
        return new Promise(function (resolve, reject) {
            apolloClient
                .mutate(Object.assign(purchasePermGraphQLMutation, {
                variables: {
                    id: id,
                }
            }))
                .then(function (_a) {
                var purchasePerm = _a.data.purchasePerm;
                var perm = purchasePerm && purchasePerm.perm;
                commit('addRefetchQueries', REFETCHED_QUERIES['HORSE_LIST'], { root: true });
                resolve(perm);
            })
                .catch(function (err) {
                var errorMessage = err.graphQLErrors && err.graphQLErrors.map(function (x) { return x.message; }).toString();
                if (errorMessage) {
                    if (errorMessage === "ERR4050" || errorMessage === "ERR2103") {
                        dispatch('notification/showErrorTransactionNotification', {
                            message: window.application.translations[errorMessage]
                        }, { root: true });
                        reject(err);
                        return;
                    }
                    if (errorMessage === "ERR2450") {
                        window.postMessageHandler.sendMessage({ "type": "LOGOUT" });
                        reject(err);
                        return;
                    }
                    if (errorMessage.indexOf("Access Denied") !== -1) {
                        reject(err);
                        return;
                    }
                }
                commit("updateErrorMessage", err, { root: true });
                reject(err);
            })
                .then(function () {
                commit("loader/disableLoader", LOADERS["PERM"], {
                    root: true
                });
            });
        });
    },
    switchToInitialMode: function (_a) {
        var commit = _a.commit, dispatch = _a.dispatch;
        commit('resetCurrentPerm');
        dispatch('horseListResetPermOption');
        commit('toggleYardFormVisibility', true);
        commit('togglePermFormVisibility', true);
    },
    storePermName: function (_a, permName) {
        var state = _a.state, commit = _a.commit, dispatch = _a.dispatch;
        new Promise(function (resolve, reject) {
            var id = state.currentPerm.id;
            var dispatchActionKey = id ? "updatePerm" : "createPerm";
            dispatch(dispatchActionKey, permName).then(function () {
                resolve();
            });
        });
    },
    storePermSilk: function (_a, silk) {
        var commit = _a.commit;
        commit("setPermSilk", silk);
    },
    createPermBox: function (_a, _b) {
        var commit = _a.commit, getters = _a.getters;
        var permBox = _b.permBox, horsesId = _b.horsesId;
        var name = permBox.name, positions = permBox.positions;
        var perm = getters.getCurrentPermId;
        return new Promise(function (resolve, reject) {
            apolloClient
                .mutate(Object.assign(createPermBoxGraphQLMutation, {
                variables: {
                    name: name,
                    positions: positions,
                    perm: perm,
                    items: horsesId
                }
            }))
                .then(function (_a) {
                var createPermBox = _a.data.createPermBox;
                var permBox = createPermBox.permBox;
                commit("addStateBox", permBox);
                resolve(permBox);
            })
                .catch(function (err) {
                commit("updateErrorMessage", err, { root: true });
                reject(err);
            })
                .then(function () {
                commit("loader/disableLoader", LOADERS["PERM"], {
                    root: true
                });
            });
        });
    },
    updatePermBox: function (_a, _b) {
        var commit = _a.commit;
        var permBox = _b.permBox, horsesId = _b.horsesId;
        var id = permBox.id, name = permBox.name, positions = permBox.positions;
        return new Promise(function (resolve, reject) {
            apolloClient
                .mutate(Object.assign(updatePermBoxGraphQLMutation, {
                variables: {
                    id: id,
                    name: name,
                    positions: positions,
                    items: horsesId
                }
            }))
                .then(function (_a) {
                var updatePermBox = _a.data.updatePermBox;
                var permBox = updatePermBox.permBox;
                var id = permBox.id;
                commit("updateStateBox", permBox);
                resolve(permBox);
            })
                .catch(function (err) {
                commit("updateErrorMessage", err, { root: true });
                reject(err);
            })
                .then(function () {
                commit("loader/disableLoader", LOADERS["PERM"], {
                    root: true
                });
            });
        });
    },
    deletePermBox: function (_a, _b) {
        var commit = _a.commit, dispatch = _a.dispatch;
        var permBox = _b.permBox;
        return __awaiter(this, void 0, void 0, function () {
            var id;
            return __generator(this, function (_c) {
                id = permBox.id;
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        apolloClient
                            .mutate(Object.assign(deletePermBoxGraphQLMutation, {
                            variables: {
                                id: id
                            }
                        }))
                            .then(function () {
                            permBox.items.edges.forEach(function (_a) {
                                var node = _a.node;
                                dispatch("removeItemsFromPermBox", { horse: node, boxId: id, removing: true });
                            });
                            commit("removeTempDeletedHorsesData", id);
                            commit("deleteStateBox", permBox);
                            resolve(null);
                        })
                            .catch(function (err) {
                            commit("updateErrorMessage", err, { root: true });
                            reject(err);
                        })
                            .then(function () {
                            commit("loader/disableLoader", LOADERS["PERM"], {
                                root: true
                            });
                        });
                    })];
            });
        });
    },
    addItemsToPermBox: function (_a, _b) {
        var commit = _a.commit;
        var boxId = _b.boxId, horsesId = _b.horsesId;
        return new Promise(function (resolve, reject) {
            apolloClient
                .mutate(Object.assign(addItemsToPermBoxGraphQLMutation, {
                variables: {
                    id: boxId,
                    items: horsesId
                }
            }))
                .then(function (_a) {
                var addItemsToPermBox = _a.data.addItemsToPermBox;
                var permBox = addItemsToPermBox.permBox;
                commit("updateStateBox", permBox);
                resolve();
            })
                .catch(function (err) {
                commit("updateErrorMessage", err, { root: true });
                reject(err);
            });
        });
    },
    removeItemsFromPermBox: function (_a, _b) {
        var state = _a.state, commit = _a.commit, getters = _a.getters, dispatch = _a.dispatch;
        var boxId = _b.boxId, horse = _b.horse, _c = _b.removing, removing = _c === void 0 ? false : _c;
        return __awaiter(this, void 0, void 0, function () {
            var horsesId;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        horsesId = horse.id;
                        if (state.activeBoxId && state.activeBoxId !== horse.permsOption)
                            return [2 /*return*/];
                        if (state.boxFormCreationMode && horse.permsOption)
                            return [2 /*return*/];
                        return [4 /*yield*/, commit("addTempDeletedHorsesData", { horseId: horsesId, boxId: boxId })];
                    case 1:
                        _d.sent();
                        return [4 /*yield*/, dispatch("horseListRemovePermOption", horsesId)];
                    case 2:
                        _d.sent();
                        if (!(window.screen.width < POPUP_MENU_WIDTH_TRIGGERING)) return [3 /*break*/, 3];
                        if (!removing) {
                            commit("setActiveBox", boxId);
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        if (!!removing) return [3 /*break*/, 5];
                        return [4 /*yield*/, dispatch("editBoxInPermForm", boxId)];
                    case 4:
                        _d.sent();
                        _d.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    },
    /** box editing in separate form page **/
    editBoxInPermForm: function (_a, boxId) {
        var commit = _a.commit;
        commit("setActiveBox", boxId);
        commit("togglePermFormVisibility", true);
    },
    /** horse list manupulation **/
    horseListAddPermOption: function (_a, _b) {
        var commit = _a.commit;
        var horseId = _b.horseId, boxId = _b.boxId;
        commit("game/mutatePermsInHorseList", { horseId: horseId, value: boxId }, { root: true });
    },
    horseListRemovePermOption: function (_a, horseId) {
        var commit = _a.commit;
        commit("game/mutatePermsInHorseList", { horseId: horseId, value: null }, { root: true });
    },
    toggleAddedToPermHorse: function (_a, _b) {
        var commit = _a.commit, state = _a.state, dispatch = _a.dispatch;
        var boxId = _b.boxId, horse = _b.horse;
        // add horse to box but do not store
        var horseId = horse.id;
        if (!boxId) {
            if (state.activeBoxId || state.boxFormCreationMode) {
                commit("game/mutatePermsInHorseList", { horseId: horseId, value: true }, { root: true });
            }
            return;
        }
        // remove horse if it is stored in box
        if (boxId !== "true") {
            commit("setReplacedHorse", horseId);
            dispatch("removeItemsFromPermBox", { horse: horse, boxId: boxId });
            return;
        }
        // remove horse which is added but not stored in box
        dispatch("horseListRemovePermOption", horseId);
    },
    horseListResetPreAddedHorses: function (_a) {
        var getters = _a.getters, dispatch = _a.dispatch;
        var horses = getters.getHorsesPreAddedToPerm;
        _.forEach(horses, function (horse) {
            dispatch('horseListRemovePermOption', horse.node.id);
        });
    },
    horseListResetPermOption: function (_a) {
        var getters = _a.getters, dispatch = _a.dispatch;
        var horses = getters.getHorsesAddedToPerm;
        _.forEach(horses, function (horse) {
            dispatch('horseListRemovePermOption', horse.node.id);
        });
    }
};
export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions
};
